import React, { useState } from 'react';
import {
	StyleSheet,
	Text,
	TextInput,
	View,
	Alert,
	TouchableOpacity,
	KeyboardAvoidingView,
	Platform,
	ActivityIndicator,
} from 'react-native';
import * as Lang from 'i18n-js';

import { LinearGradient } from 'expo-linear-gradient';
import helpers, { api } from '../helpers';
import { ALERT_TITLE } from '../MultiAppSettings';

const colors = helpers.appColors;
export default function ResetPasswordConfirmPage(props: { navigation: any }) {
	const [isLoading, setLoading] = useState(false);
	const [code, setCode] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [repeatPassword, setRepeatPassword] = useState<string>('');

	const resetPassword = async () => {
		if (password !== repeatPassword) {
			Alert.alert(ALERT_TITLE, Lang.t('The given passwords must be the same'));
		} else if (password.length < 5) {
			Alert.alert(ALERT_TITLE, Lang.t('The password must be longer'));
		} else if (password.length < 4) {
			Alert.alert(ALERT_TITLE, Lang.t('The code is not valid'));
		} else {
			setLoading(true);
			const resp = await api('resetPassword', {
				newPassword: password,
				code: code,
			});
			setLoading(false);
			if (resp && !resp.error) {
				props.navigation.popToTop();
			} else {
				Alert.alert(ALERT_TITLE, Lang.t('UNKOWN_ERROR'));
			}
		}
	};
	return (
		<>
			{isLoading && (
				<View
					style={{
						position: 'absolute',
						justifyContent: 'center',
						alignItems: 'center',
						top: 0,
						left: 0,
						height: '100%',
						width: '100%',
						backgroundColor: 'rgba(0,0,0,0.5)',
						zIndex: 9999,
					}}>
					<ActivityIndicator size="large" />
				</View>
			)}
			<KeyboardAvoidingView style={styles.bg} behavior="padding">
				<View style={styles.innerContainer}>
					<TextInput
						style={styles.input}
						keyboardType="number-pad"
						placeholder={Lang.t('Code')}
						placeholderTextColor="#a09fb0"
						onChangeText={setCode}
					/>
					<TextInput
						style={styles.input}
						autoCompleteType="password"
						secureTextEntry={true}
						placeholder={Lang.t('Password')}
						placeholderTextColor="#a09fb0"
						onChangeText={setPassword}

						// onSubmitEditing={whatToFocus}
						// onBlur={onBlur}
						// onChangeText={onChange}
						// value={value}
					/>
					<TextInput
						style={styles.input}
						autoCompleteType="password"
						secureTextEntry={true}
						placeholder={Lang.t('Confirm Password')}
						placeholderTextColor="#a09fb0"
						onChangeText={setRepeatPassword}

						// onSubmitEditing={whatToFocus}
						// onBlur={onBlur}
						// onChangeText={onChange}
						// value={value}
					/>
					<TouchableOpacity onPress={resetPassword} style={{ width: '100%' }}>
						<LinearGradient colors={[colors.lightOrange, colors.orange]} style={styles.input}>
							<Text style={[styles.textInput, { color: '#FFF' }]}>{Lang.t('RESET_PASS')}</Text>
						</LinearGradient>
					</TouchableOpacity>
				</View>
			</KeyboardAvoidingView>
		</>
	);
}

const styles = StyleSheet.create({
	innerContainer: {
		height: '100%',
		maxHeight: '90%',
		justifyContent: 'space-evenly',
		flexDirection: 'column',
	},
	btnContainer: {
		shadowColor: colors.orange,
		shadowOffset: {
			width: 0,
			height: 0,
		},
		shadowOpacity: 0.4,
		shadowRadius: 16.0,
	},
	btn: {
		width: '100%',
		height: 60,
		borderRadius: 15,

		justifyContent: 'center',
		alignItems: 'center',
	},

	buttons: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		paddingTop: 40,
	},
	buttonText: {
		fontSize: 25,
		color: '#a09fb0',
	},
	button: {
		height: 105,
		width: 105,

		justifyContent: 'center',
		alignItems: 'center',
	},
	inputContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	input: {
		height: 65,
		width: '100%',
		borderRadius: 18,
		backgroundColor: '#FFF',

		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 4,
		},
		shadowOpacity: 0.2,
		shadowRadius: 10.0,

		justifyContent: 'center',
		alignItems: 'center',
		padding: 10,
	},
	blueInput: {},
	textInput: {
		color: '#000',
		fontWeight: 'bold',
		fontSize: 20,
	},
	bg: {
		backgroundColor: '#ECECF3',
		width: '100%',
		height: '100%',
		padding: 30,

		heiight: '100%',
	},
	titleContainer: {
		width: 172,
		paddingBottom: 30,
	},
	title: {
		fontSize: 35,
		color: '#a09fb0',
		textAlign: 'center',
	},
});
