import { FunctionComponent, useState, useEffect } from 'react';
import * as Localization from 'expo-localization';
import React, { TouchableOpacity, Text, Alert } from 'react-native';
import { Input } from './Input';
import { ALERT_TITLE } from '../../../MultiAppSettings';
import type Question from '../Question';
import Lang from '../../locale';

function formatNumber(input: number | undefined): string {
	if (input === undefined) {
		return '';
	}

	const val = input.toString();
	let value = '';
	try {
		value = new Intl.NumberFormat('da-DK', { maximumSignificantDigits: 21 }).format(
			Number(val.split('.').join('').split(',').join(''))
		);
	} catch (error) {
		console.log('Input error', error);
		value = val.split('.').join('').split(',').join('');
	}
	return value;
}

function useCurrency(currencies: Question['children'], defaultQuestionId?: number) {
	const [currency, setCurrency] = useState<Question>(currencies[0]);

	const setCurrencyId = (id: number) => {
		const targetCurrency = currencies.find(question => question.questionID === id);
		if (targetCurrency) {
			setCurrency(targetCurrency);
		}
	};

	useEffect(() => {
		const country = Localization.timezone.toLowerCase();
		const targetCurrency = currencies.find(question =>
			country.includes(question.options.at(0)?.toLocaleLowerCase() ?? 'NOOOOOOOP')
		);
		if (targetCurrency) {
			setCurrency(targetCurrency);
		}
	}, []);

	useEffect(() => {
		if (defaultQuestionId) {
			setCurrencyId(defaultQuestionId);
		}
	}, [defaultQuestionId]);

	return {
		currency,
		setCurrencyId,
		askCurrency: () => {
			Alert.alert(ALERT_TITLE, Lang.t('CHANGE_CURRENCY_COMPONENT'), [
				{
					text: 'Cancel',
					onPress: () => {},
					style: 'cancel',
				},
				...currencies.map(currency => ({
					text: currency.question,
					onPress: () => setCurrency(currency),
				})),
			]);
		},
	};
}

export const NumberInputCountry: FunctionComponent<{
	question: Question;
	defaultCurrencyId?: number;
	defaultNumber?: number;
	onChange: (value: number, currencyQuestionId: number) => void;
}> = ({ question, onChange, defaultCurrencyId, defaultNumber }) => {
	const [number, setNumber] = useState<undefined | number>(defaultNumber);
	const { currency, askCurrency, setCurrencyId } = useCurrency(question.children, defaultCurrencyId);

	useEffect(() => {
		if (number && currency) {
			const changes = number !== defaultNumber || currency.questionID !== defaultCurrencyId;

			if (changes) {
				onChange(number, currency.questionID);
			}
		}
	}, [number, currency]);

	useEffect(() => {
		if (number !== defaultNumber) {
			setNumber(defaultNumber);
		}

		if (currency.questionID !== defaultCurrencyId && defaultCurrencyId) {
			setCurrencyId(defaultCurrencyId);
		}
	}, [defaultCurrencyId, defaultNumber]);

	return (
		<Input
			value={formatNumber(number)}
			keyboardType="number-pad"
			onChange={([text]) => {
				if (text.length > 0) {
					setNumber(Math.min(Number(text.split('.').join('')), Number.MAX_SAFE_INTEGER));
				}
			}}
			rightSide={
				<TouchableOpacity
					onPress={askCurrency}
					style={{ height: '100%', paddingLeft: 15, paddingRight: 15, justifyContent: 'center' }}>
					<Text>{currency?.question}</Text>
				</TouchableOpacity>
			}
		/>
	);
};
