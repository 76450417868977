import React, { useState } from 'react';
import { View, Dimensions, StyleSheet, TouchableOpacity, Text, Image } from 'react-native';
import { FontAwesome as Icon } from '@expo/vector-icons';

import Lang from '../locale';
import Question from '../classes/Question';

import { appColors as colors } from '../../helpers';
import { IS_NOVOTI_APP } from '../../MultiAppSettings';

const HEADER_HEIGHT = 321;
const screenHeight = Dimensions.get('window').height;

// const progressX = new Animated.Value(0);

type questionTypes = Parameters<Question['get']>;

const validateAnswers = (children: Question[]) => {
	for (let i = 0; i < children.length; i++) {
		const child = children[i];
		// if (child.type === 'notNeeded') {
		// 	continue;
		// }

		if (child.functionalTypes.includes(child.type)) {
			const check = validateAnswers(child.children);
			if (!check) {
				return false;
			}
		}

		if (child.response.length === 0) {
			return false;
		}
	}
	return true;
};

const OverMenuQuestion: React.FunctionComponent<{
	items: Question[];
	getArgs: questionTypes;
	ctx: { [key: string]: any };
}> = props => {
	const [currPage, setPage] = useState(-1);
	const targetItem = props.items[currPage];
	return (
		<View
			style={{
				width: '100%',
				minHeight: screenHeight - HEADER_HEIGHT + 30,
				alignItems: 'center',
				flexDirection: 'row',
				flexGrow: 1,
			}}
			key={currPage}>
			{currPage === -1 ? (
				<View style={pageStyles.box}>
					{props.items.map((item, key) => (
						<TouchableOpacity
							style={pageStyles.button}
							onPress={() => {
								setPage(key);
							}}
							key={key}>
							{validateAnswers(props.items[key].children) && (
								<Icon style={{ marginLeft: 5 }} name="check" size={22} color={colors.alternativeGreen} />
							)}
							<Text style={{ flexGrow: 1, textAlign: 'center' }}>{Lang.t(item.correct)}</Text>
						</TouchableOpacity>
					))}

					{IS_NOVOTI_APP && (
						<View style={{ width: '100%', alignItems: 'center', top: 45 }}>
							<Image style={{ width: 80, height: 80 }} source={require('./assets/novotiLogo.png')} />
						</View>
					)}
				</View>
			) : (
				<View>
					<View style={pageStyles.titleContainer}>
						<Text style={pageStyles.title}>{Lang.t(targetItem.correct)}</Text>
					</View>
					{targetItem.children[0].get(
						props.getArgs.openModal,
						props.getArgs.showContent,
						props.getArgs.openCam,
						props.getArgs.getLocation,
						props.getArgs.triggerEvent,
						props.getArgs.sheet,
						props.getArgs.saveObs,
						{
							...props.ctx,
							resetOverMenu: () => setPage(-1),
						}
					)}
				</View>
			)}
		</View>
	);
};

const pageStyles = StyleSheet.create({
	title: {
		fontSize: 40,
		color: colors.white,
	},
	titleContainer: {
		position: 'absolute',
		top: 20,
		width: '100%',
		alignItems: 'center',
	},
	backBtn: {
		position: 'absolute',
		top: 10,
	},
	box: {
		// position: "absolute",
		width: '90%',
		borderRadius: 15,
		marginLeft: '5%',
		backgroundColor: colors.bgWhite,
		padding: 15,
		marginTop: 20,
	},
	button: {
		borderWidth: 2,
		borderColor: colors.borderColor,

		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 15,
		padding: 15,
		paddingLeft: 6,
		paddingRight: 6,
		minWidth: 30,
		margin: 10,
		flexDirection: 'row',
		backgroundColor: colors.white,
	},
	questionContainer: {
		// height: 100,
		// width: 100,
		justifyContent: 'center',
		height: screenHeight - HEADER_HEIGHT,
	},
});

export default OverMenuQuestion;
