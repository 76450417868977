import React from 'react';
import {
	Text,
	StyleSheet,
	View,
	Platform,
	TextInput,
	Dimensions,
	Alert,
	TouchableOpacity,
	ActivityIndicator,
	ScrollView,
	Image,
	KeyboardAvoidingView,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { FontAwesome as Icon } from '@expo/vector-icons';

import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';
import * as Permissions from 'expo-permissions';
import * as Localization from 'expo-localization';

import Lang from '../locale';

import MapView from 'react-native-maps';

import { questionTemplate, baseProps } from './questionTemplate';
import { appStyles, DAWA, api, IP, State, calcCrow } from '../../helpers';
import { ALERT_TITLE } from '../../MultiAppSettings';

import { useColors, appColors as colors } from '../../multiApp';

import SelectModal from '../UI/SelectModal';
import SearchIcon from '../UI/SearchIcon';
import type Question from './Question';
import { Input, NumberInputCountry } from './questionTypes';

const screenWidth = Dimensions.get('window').width;

interface stateData {
	answer: string[];
	history: string[];
	responses: (string | number | boolean)[];
	isLoading: boolean;
	show: boolean;
	data: any[];
	preview: false | string;
	location: {
		latitude: number;
		longitude: number;
	};
}

export default class QuestionBox extends React.Component<
	questionTemplate & baseProps & { rawQuestion: Question },
	stateData
> {
	constructor(props: questionTemplate) {
		super(props);
		this.state = {
			answer: [],
			history: [],
			responses: props.responses || [],
			isLoading: false,
			show: false,
			data: [],
			preview: false,
			location: {
				latitude: 55.501887,
				longitude: 9.794188,
			},
		};
	}
	_initialized = false;
	responseEvents: ((data: (string | number | boolean)[]) => void)[] = [];

	componentDidUpdate(prevProps: questionTemplate) {
		if (JSON.stringify(prevProps.responses || []) !== JSON.stringify(this.props.responses || [])) {
			this.setState({
				responses: this.props.responses || [],
			});
		}
	}

	OVERLAY_TYPES = ['addressInput'];

	async getPermissionAsync() {
		if (Constants.platform?.ios) {
			const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
			if (status !== 'granted') {
				alert('Sorry, we need camera roll permissions to make this work!');
			}
		}
	}

	async _pickImage() {
		try {
			let result = await ImagePicker.launchImageLibraryAsync({
				mediaTypes: ImagePicker.MediaTypeOptions.Images,
				allowsEditing: false,
				base64: true,
				aspect: [4, 3],
				quality: 0.7,
			});
			if (!result.cancelled) {
				return `data:image/jpg;base64,${result.base64}`;
			}
		} catch (E) {}

		return false;
	}

	_locationRequested = false;
	async getLocation() {
		if (!this._locationRequested) {
			this._locationRequested = true;
			const { coords } = await this.props.getLocation();

			this.setState({
				location: coords,
			});
		}
	}

	boxWrap(title: string, content: React.ReactElement) {
		return (
			<View
				style={[
					styles.box,
					{ zIndex: this.OVERLAY_TYPES.includes(this.props.type) ? 9999 : 0 },
					this.props.onlyRaw
						? { padding: 0, width: '100%', marginLeft: 0, backgroundColor: 'transparent', borderRadius: 0 }
						: {},
					appStyles.boxShadow,
				]}>
				{!!title ? (
					<View style={styles.titleContainer}>
						<Text style={styles.title}>{Lang.t(title)}</Text>
					</View>
				) : (
					<View style={{ height: 5, width: '100%' }} />
				)}
				{content}
			</View>
		);
	}

	setSelector = (d: any, callback: (data: any) => void) => {
		if (this.props.showContent) {
			this.props.showContent(
				<SelectModal
					show={true}
					data={d.data}
					multi={d.multi}
					hide={data => {
						if (this.props.showContent) {
							this.props.showContent(null, false);
						}
						callback(data);
					}}
				/>,
				true
			);
		}
	};

	setResponse(data: (string | number | boolean)[]) {
		this.setState({
			responses: data,
		});
		this.props.setResponse(data);
		this.responseEvents.forEach(func => func(data));
		this.forceUpdate();
	}

	getComponent = () => {
		const IS_WEB = Platform.OS === 'web';
		const value = this.state.responses.length > 0 ? this.state.responses[0] : false;
		switch (this.props.type) {
			// case "textInput":
			//     return this.boxWrap(this.props.question || "", (<View style={styles.rangeContainer}>
			//                 <View style={styles.inputContainer}>
			//                     <TextInput style={styles.input}/>
			//                 </View>
			//         </View>));
			// break;
			case 'singlePageQuestions':
				break;

			case 'range':
				{
					const FROM: number = Number(this.props.options && this.props.options.length > 0 ? this.props.options[0] : 0);
					const TO: number = Number(this.props.options && this.props.options.length > 1 ? this.props.options[1] : 5);
					return this.boxWrap(
						this.props.question || '',
						<View style={styles.rangeContainer}>
							{[...Array(TO - FROM + 1)].map((d, i) => {
								const TARGET_VAL = i + FROM;
								const I_STR = TARGET_VAL.toString();
								return (
									<React.Fragment key={i}>
										{i > 0 ? <View style={styles.rangeSpacer} /> : null}
										<TouchableOpacity
											style={[styles.circleBtn, value && I_STR === value ? styles.circleBtnSelected : null]}
											onPress={() => {
												this.setResponse([I_STR]);
											}}>
											<Text
												style={[styles.circleBtnText, value && I_STR === value ? styles.circleBtnTextSelected : null]}>
												{TARGET_VAL}
											</Text>
										</TouchableOpacity>
									</React.Fragment>
								);
							})}
						</View>
					);
				}
				break;
			case 'smileys':
				{
					const smileys = [
						require('../UI/assets/greenSmiley.png'),
						require('../UI/assets/yellowSmiley.png'),
						require('../UI/assets/redSmiley.png'),
					];
					return this.boxWrap(
						this.props.question || '',
						<View style={styles.rangeContainer}>
							{smileys.map((smiley, i) => {
								const TARGET_VAL = i;
								const I_STR = TARGET_VAL.toString();

								return (
									<React.Fragment key={i}>
										{i > 0 ? <View style={styles.rangeSpacer} /> : null}
										<TouchableOpacity
											style={[styles.circleBtn, value && I_STR === value ? styles.circleBtnSelected : null]}
											onPress={() => {
												this.setResponse([I_STR]);
											}}>
											<Image source={smiley} style={{ width: 70, height: 70 }} resizeMode="contain" />
										</TouchableOpacity>
									</React.Fragment>
								);
							})}
						</View>
					);
				}
				break;
			case 'smileys_colored':
				{
					const smileys = [
						require('../UI/assets/smiley1.png'),
						require('../UI/assets/smiley2.png'),
						require('../UI/assets/smiley3.png'),
					];
					return this.boxWrap(
						this.props.question || '',
						<View style={styles.rangeContainer}>
							{smileys.map((smiley, i) => {
								const TARGET_VAL = i;
								const I_STR = TARGET_VAL.toString();

								return (
									<React.Fragment key={i}>
										{i > 0 ? <View style={styles.rangeSpacer} /> : null}
										<TouchableOpacity
											style={[styles.circleBtn, value && I_STR === value ? styles.circleBtnSelected : null]}
											onPress={() => {
												this.setResponse([I_STR]);
											}}>
											<Image
												source={smiley}
												style={{ width: 70, height: 70, tintColor: I_STR === value ? colors.white : colors.orange }}
												resizeMode="contain"
											/>
										</TouchableOpacity>
									</React.Fragment>
								);
							})}
						</View>
					);
				}
				break;
			case 'message':
				return this.boxWrap(
					'',
					<View style={styles.rangeContainer}>{this.props.question && <Text>{Lang.t(this.props.question)}</Text>}</View>
				);
				break;
			//<Icon name={"search"} size={20} color="#000" />
			case 'addressInput':
				return (
					<View style={this.state.show ? { marginBottom: 140 } : {}}>
						{this.boxWrap(
							this.props.question || '',
							<View style={[styles.rangeContainer]}>
								<KeyboardAvoidingView behavior="height" style={{ width: '100%' }}>
									<View style={styles.inputContainer}>
										<TextInput
											ref="input"
											style={styles.input}
											onFocus={() => this.setState({ show: true })}
											defaultValue={this.state.responses[0]?.toString() ?? ''}
											onChange={async e => {
												//e.nativeEvent.text
												this.setState({
													isLoading: true,
												});
												let resp = IS_WEB
													? await api('dawaSearch', { value: (e.target as any).value })
													: await DAWA(`/datavask/adresser?betegnelse=${e.nativeEvent.text}`);
												if ((!IS_WEB && resp) || (IS_WEB && !resp.error && resp.data)) {
													if (IS_WEB) {
														resp = resp.data;
													}
													this.setState({
														data: resp.resultater.map(d => ({
															addr:
																d.adresse.adresseringsvejnavn +
																' ' +
																d.adresse.husnr +
																(d.adresse.etage ? ', ' + d.adresse.etage : '') +
																(d.adresse.dør ? ' ' + d.adresse.dør : ''),
															city: d.adresse.postnrnavn,
															id: d.adresse.id,
														})),
														isLoading: false,
													});
												}
											}}
										/>
										{this.state.isLoading ? <ActivityIndicator /> : <SearchIcon animate={false} />}
									</View>
									{/* <Button title="test" onPress={() => this.setState({isLoading: true})}/> */}
									{this.state.show && (
										<View style={[styles.inputSuggestions, appStyles.boxShadow]}>
											<LinearGradient
												start={[0, 0]}
												colors={[colors.orange, colors.red]}
												style={styles.suggestionGradient}
											/>
											<ScrollView scrollEnabled={true}>
												{this.state.data.map((d, i) => (
													<TouchableOpacity
														style={styles.suggestion}
														key={i}
														onPress={() => {
															this.setResponse([d.addr, d.id]);
															this.setState({
																show: false,
															});
														}}>
														<View style={styles.suggestionInfo}>
															<Text style={styles.suggestionAddr}>{d.addr}</Text>
															<Text style={styles.suggestionCity}>{d.city}</Text>
														</View>
														<Image source={require('../UI/assets/arrow.png')} style={styles.suggestionIcon} />
													</TouchableOpacity>
												))}
											</ScrollView>
										</View>
									)}
								</KeyboardAvoidingView>
							</View>
						)}
					</View>
				);
				break;
			case 'radio':
				{
					const allResponses = (this.props.children || [])
						.map(child =>
							typeof child.response === 'string' ||
							typeof child.response === 'number' ||
							typeof child.response === 'boolean'
								? [child.response]
								: (child.response ?? []).map(d => child.children?.find(child => child.questionID === d).question)
						)
						.reduce((a, b) => [...a, ...b], []);
					return this.boxWrap(
						this.props.question || '',
						<React.Fragment>
							<TouchableOpacity
								onPress={() =>
									this.setSelector(
										{
											show: true,
											multi: false,
											data: this.props.children || [],
										},
										vals => {
											this.setResponse(vals.map(d => d.response).flat());
											// this.props.setResponse(this.props.responses || []);
										}
									)
								}
								style={styles.btn}>
								{allResponses.length > 0 ? (
									<Text>{allResponses.join(', ')}</Text>
								) : (
									<Text>{Lang.t('Tryk for at vælge en valgmulighed')}</Text>
								)}
							</TouchableOpacity>
						</React.Fragment>
					);
				}
				break;
			case 'radio-gps':
				{
					const allResponses = (this.props.children || [])
						.map(child =>
							(child.response || []).map(d => child.children.find(child => child.questionID === d).question)
						)
						.reduce((a, b) => [...a, ...b], []);

					this.getLocation();

					const { location } = this.state;
					const distanced = (this.props.children || []).map(child => {
						if (child.children) {
							child.children = (child.children || [])
								.map(child => {
									if (!child.tempData) {
										child.tempData = {};
									}
									child.tempData.distance = calcCrow(
										location.latitude,
										location.longitude,
										Number(child.options?.[0] ?? 0),
										Number(child.options?.[1] ?? 0)
									);
									return child;
								})
								.sort((a, b) => a?.tempData?.distance - b?.tempData?.distance);
							// .map(question => ({
							// 	...question,
							// 	secondaryQuestion:
							// 		question.tempData.distance && `${parseFloat(question.tempData.distance).toFixed(1)}km`,
							// }));
						}

						return child;
					});

					return this.boxWrap(
						this.props.question || '',
						<React.Fragment>
							<TouchableOpacity
								onPress={() => {
									// const wrapperQ = new Question(0, 'radio-gps', distanced, "Title")
									this.setSelector(
										{
											show: true,
											multi: false,
											data: distanced,
										},
										vals => {
											// this.setResponse(this.props.responses || []);
											this.setResponse(vals.map(d => d.response).flat());
										}
									);
								}}
								style={styles.btn}>
								{allResponses.length > 0 ? (
									<Text>{allResponses.map(d => Lang.t(d)).join(', ')}</Text>
								) : (
									<Text>{Lang.t('Tryk for at vælge en valgmulighed')}</Text>
								)}
							</TouchableOpacity>
						</React.Fragment>
					);
				}
				break;

			case 'select':
				{
					const allResponses = (this.props.children || [])
						.map(child =>
							(child.response || []).map(d => child.children.find(child => child.questionID === d).question)
						)
						.reduce((a, b) => [...a, ...b], []);
					return this.boxWrap(
						this.props.question || '',
						<React.Fragment>
							<TouchableOpacity
								onPress={() =>
									this.setSelector(
										{
											show: true,
											multi: true,
											data: this.props.children || [],
										},
										vals => {
											// this.props.setResponse(this.props.responses || []);
											this.setResponse(vals.map(d => d.response).flat());
										}
									)
								}
								style={styles.btn}>
								{allResponses.length > 0 ? (
									<Text>{allResponses.map(d => Lang.t(d)).join(', ')}</Text>
								) : (
									<Text>{Lang.t('Tryk for at vælge en valgmulighed')}</Text>
								)}
							</TouchableOpacity>
						</React.Fragment>
					);
				}
				break;
			case 'assignTo':
				{
					const allResponses = (this.props.children || [])
						.map(child =>
							(child.response || []).map(d => child.children.find(child => child.questionID === d).question)
						)
						.flat();
					// .reduce((a, b) => [...a, ...b], []);
					return this.boxWrap(
						this.props.question || '',
						<React.Fragment>
							<TouchableOpacity
								onPress={() =>
									this.setSelector(
										{
											show: true,
											multi: false,
											data: (this.props.children || []).sort((a, b) => {
												const check: (curr: Question, val: Number) => boolean = (curr: Question, val: Number) =>
													curr.questionID === val || curr.children.some(child => check(child, val));
												const aCheck = check(a, State.userInfo?.department || 1);
												const bCheck = check(b, State.userInfo?.department || 1);

												if (aCheck && !bCheck) {
													return -1;
												} else if (bCheck && !aCheck) {
													return 1;
												}
												return 0;
											}),
										},
										vals => {
											// this.props.setResponse(this.props.responses || []);

											this.setResponse(vals.map(d => d.response).flat());
										}
									)
								}
								style={styles.btn}>
								{allResponses.length > 0 ? (
									<Text>{allResponses.join(', ')}</Text>
								) : (
									<Text>{Lang.t('Tryk for at vælge en valgmulighed')}</Text>
								)}
							</TouchableOpacity>
						</React.Fragment>
					);
				}
				break;

			case 'upload': // TODO: Internet check
				{
					// const [images, setImages] = useState([]);
					const handleImage = async (newImage: string) => {
						let images: string[] = [...(this.state.responses || [])];
						const targetI = images.length;

						images = [...images, ''];
						this.setResponse(images);

						const { coords } = await this.props.getLocation();
						const upload = await api('uploadImage', { data: newImage, location: coords });
						if (upload && !upload.error) {
							images[targetI] = upload.data;
							this.setResponse(images);
						} else {
							this.setResponse(images.filter((d, key) => key < images.length - 1));
						}
						// uploadImage(newImage).then((url:string) => {

						// });
					};

					return this.boxWrap(
						this.props.question || '',
						<React.Fragment>
							<View style={styles.rangeContainer}>
								<TouchableOpacity
									onPress={async () => {
										const newImage = await this._pickImage();
										if (newImage) {
											handleImage(newImage);
										}
									}}
									style={[
										styles.greyBtn,
										{ borderRightWidth: 0.5, borderTopRightRadius: 0, borderBottomRightRadius: 0 },
									]}>
									<Icon name="photo" size={18} color="#000" />
								</TouchableOpacity>
								<TouchableOpacity
									onPress={async () => {
										// this.props.navigation.push("Cam");
										// const newImage = await _pickImage(); // TODO: use camera-component
										// if(newImage)
										// {
										//     handleImage(newImage);
										// }
										if (this.props.openCam) {
											this.props.openCam(true, data => {
												if (data) {
													handleImage(`data:image/jpg;base64,${data.base64}`);
												}
											});
										}
									}}
									disabled={IS_WEB}
									style={[
										styles.greyBtn,
										{
											borderLeftWidth: 0.5,
											borderTopLeftRadius: 0,
											borderBottomLeftRadius: 0,
											opacity: IS_WEB ? 0.4 : 1,
										},
									]}>
									<Icon name="camera" size={18} color="#000" />
								</TouchableOpacity>
							</View>
							{this.state.responses.length > 0 ? (
								<ScrollView style={{ height: 100, width: '100%', flexDirection: 'row' }} horizontal={true}>
									{this.state.responses.map((image, i) =>
										typeof image === 'number' ||
										typeof image === 'boolean' ||
										(typeof image === 'string' && image.length === 0) ? (
											<View
												key={i}
												style={{
													height: 100,
													width: 80,
													backgroundColor: colors.bgGrey,
													justifyContent: 'center',
													alignItems: 'center',
												}}>
												<ActivityIndicator />
											</View>
										) : (
											<View key={i}>
												<TouchableOpacity onPress={() => this.setState({ preview: image })}>
													<Image
														source={{ uri: `${IP}/image/${image}` }}
														style={{ width: 80, height: 100, marginRight: 10 }}
													/>
												</TouchableOpacity>
												<TouchableOpacity
													onPress={() => this.setResponse(this.state.responses.filter((_, key) => key !== i))}>
													<Icon
														name="times"
														size={30}
														color={colors.red}
														style={{ position: 'absolute', top: -97, left: 50 }}
													/>
												</TouchableOpacity>
											</View>
										)
									)}
								</ScrollView>
							) : null}
							{this.state.preview && (
								<TouchableOpacity
									onPress={() => this.setState({ preview: false })}
									style={{
										position: 'absolute',
										height: 245,
										width: screenWidth,
										top: -25,
										left: -20,
										backgroundColor: 'rgba(0, 0, 0, 0.5)',
										justifyContent: 'center',
										alignItems: 'center',
									}}>
									<Image
										resizeMode="contain"
										source={{ uri: `${IP}/image/${this.state.preview}` }}
										style={{ width: 255, height: '100%' }}
									/>
								</TouchableOpacity>
							)}
						</React.Fragment>
					);
				}
				break;
			case 'textInput':
				{
					return this.boxWrap(
						this.props.question || '',
						<Input value={value} onChange={text => this.setResponse(text)} />
					);
				}
				break;
			case 'input':
				{
					return this.boxWrap(
						this.props.question || '',
						<Input value={value} multiLine={false} onChange={text => this.setResponse(text)} />
					);
				}
				break;

			case 'numberInput-country-new':
				return this.boxWrap(
					this.props.question || '',
					<NumberInputCountry
						defaultCurrencyId={Number(this.state.responses[0]) || undefined}
						defaultNumber={Number(this.state.responses[1]) || undefined}
						question={this.props.rawQuestion}
						onChange={(value, currencyQuestionId) => {
							this.setResponse([currencyQuestionId, value]);
						}}
					/>
				);
				break;
			// case 'numberInput-country':
			// 	{
			// 		const country = Localization.timezone.toLowerCase();
			// 		const current = this.props.children?.find(d => d.questionID === this.state.responses[0]);
			// 		let selectedID = current?.questionID;
			// 		const value = this.state.responses.length > 1 ? this.state.responses[1] : false;
			// 		if (!current && !this._initialized && this.props.children && (this.props.children?.length ?? 0) > 0) {
			// 			this._initialized = true;
			// 			let newSelected = [...this.props.children].find(
			// 				d => (d.options ?? []).length > 0 && d.options?.[0] && country.includes(d.options[0].toLowerCase())
			// 			);
			// 			if (newSelected?.questionID) {
			// 				selectedID = newSelected.questionID;
			// 				if (!newSelected) {
			// 					newSelected = this.props.children[0];
			// 				}
			// 				requestAnimationFrame(() => {
			// 					if (newSelected?.questionID) {
			// 						const value = this.state.responses.length > 1 ? this.state.responses[1] : false;
			// 						this.setResponse([newSelected?.questionID, value]);
			// 					}
			// 				});
			// 			}
			// 		}

			// 		const changeToken = () => {
			// 			const children = this.props.children ?? [];
			// 			if (children?.length > 1) {
			// 				Alert.alert(ALERT_TITLE, Lang.t('CHANGE_CURRENCY_COMPONENT'), [
			// 					{
			// 						text: 'Cancel',
			// 						onPress: () => {},
			// 						style: 'cancel',
			// 					},
			// 					...children.map(currency => ({
			// 						text: currency.question,
			// 						onPress: () => currency.questionID && this.setResponse([currency.questionID, value]),
			// 					})),
			// 				]);
			// 			}
			// 		};
			// 		return this.boxWrap(
			// 			this.props.question || '',
			// 			<Input
			// 				value={value}
			// 				keyboardType="number-pad"
			// 				onChange={text => {
			// 					const val = text[0];
			// 					if (val && val.length > 0) {
			// 						let value = '';
			// 						try {
			// 							value = new Intl.NumberFormat('da-DK', { maximumSignificantDigits: 21 }).format(
			// 								Number(val.split('.').join('').split(',').join(''))
			// 							);
			// 						} catch (error) {
			// 							console.log('Input error', error);
			// 							value = val.split('.').join('').split(',').join('');
			// 						}

			// 						this.setResponse([selectedID, value]);
			// 					}
			// 				}}
			// 				rightSide={
			// 					<TouchableOpacity
			// 						onPress={changeToken}
			// 						style={{ height: '100%', paddingLeft: 15, paddingRight: 15, justifyContent: 'center' }}>
			// 						<Text>{current?.question}</Text>
			// 					</TouchableOpacity>
			// 				}
			// 			/>
			// 		);
			// 	}
			// 	break;
			case 'numberInput':
				{
					return this.boxWrap(
						this.props.question || '',
						<Input value={value} keyboardType="number-pad" onChange={text => this.setResponse(text)} />
					);
				}
				break;
			case 'multilineInput': {
				return this.boxWrap(
					this.props.question || '',
					<Input value={value} keyboardType="default" multiLine={true} onChange={text => this.setResponse(text)} />
				);
				break;
			}
			// case "draw": // TODO: Check internet connection
			// // TODO: Use a modal for this content
			//     return <View style={{height: 200}}>
			//                 <SignatureScreen
			//                 descriptionText="Underskriv"
			//                 clearText="Nulstil"
			//                 confirmText="Gem"
			//                 />
			//             </View>
			//     return (<TouchableOpacity style={styles.btn}>
			//             <Text>Underskriv</Text>

			//         </TouchableOpacity>);
			case 'mapPicker':
				{
					const IS_PLACED = this.state.responses && this.state.responses.length > 1;
					if (!IS_PLACED) {
						this.props.getLocation().then(location => {
							this.setResponse([location.coords.latitude.toString(), location.coords.longitude.toString()]);
						});
					}
					const sheet = this.props.sheet;
					return this.boxWrap(
						this.props.question || '',
						<MapView
							style={styles.map}
							onPress={event => {
								this.setResponse(
									Platform.OS === 'web'
										? [event.latLng.lat(), event.latLng.lng()]
										: [
												event.nativeEvent.coordinate.latitude.toString(),
												event.nativeEvent.coordinate.longitude.toString(),
										  ]
								);
							}}
							zoomEnabled={true}
							initialRegion={{
								latitude: sheet.location.lat,
								longitude: sheet.location.long,
								latitudeDelta: 0.0922 / 30,
								longitudeDelta: 0.0421 / 30,
							}}>
							{IS_PLACED && (
								<MapView.Marker
									coordinate={{
										latitude: Number(this.state.responses[0]),
										longitude: Number(this.state.responses[1]),
									}}
								/>
							)}
						</MapView>
					);
				}
				break;
			case 'boolean':
				return this.boxWrap(
					this.props.question || '',
					<View style={styles.rangeContainer}>
						{(this.props.options || []).map((d, i) => {
							const I_STR = typeof value === 'boolean' ? NaN : Number(value); //.toString();
							return (
								<React.Fragment key={i}>
									{i > 0 ? <View style={styles.rangeSpacer} /> : null}
									<TouchableOpacity
										style={[styles.circleBtn, !Number.isNaN(I_STR) && I_STR === i ? styles.circleBtnSelected : null]}
										onPress={() => {
											// setValue(i);
											this.setResponse([i.toString()]);
											// this.setResponse([
											//     I_STR
											// ]);
										}}>
										<Text
											style={[
												styles.circleBtnText,
												!Number.isNaN(I_STR) && I_STR === i ? styles.circleBtnTextSelected : null,
											]}>
											{d}
										</Text>
									</TouchableOpacity>
								</React.Fragment>
							);
						})}
					</View>
				);
				break;
			default:
				console.log('Component type not found!', this.props.type);
				return null;
				break;
		}
		return null;
	};

	render() {
		return this.getComponent();
	}
}

const styles = useColors(colors =>
	StyleSheet.create({
		map: {
			height: 300,
			width: '100%',
			borderRadius: 5,
		},
		btn: {
			borderRadius: 10,
			padding: 10,
			borderWidth: 2,
			borderColor: colors.borderColor,
			justifyContent: 'center',
			alignItems: 'center',
		},
		greyBtn: {
			borderRadius: 10,
			padding: 10,
			width: '50%',
			borderWidth: 2,
			borderColor: colors.borderColor,
			justifyContent: 'center',
			alignItems: 'center',
		},
		titleContainer: {
			flexDirection: 'row',
		},
		titleBtns: {
			flexDirection: 'row',
		},
		titleBtn: {
			padding: 5,
			paddingTop: 0,
		},

		suggestionGradient: {
			width: '100%',
			height: 3,
		},
		suggestion: {
			paddingLeft: 10,
			paddingRight: 10,
			flexDirection: 'row',
			height: 39,
			alignItems: 'center',
		},
		suggestionInfo: {
			flexDirection: 'row',
			flexGrow: 1,
		},
		suggestionAddr: {
			fontSize: 15,
		},
		suggestionCity: {
			fontSize: 15,
			paddingLeft: 10,
			color: colors.textGrey,
		},
		suggestionIcon: {
			height: 10,
			width: 10,
			marginRight: 6,
		},
		inputSuggestions: {
			backgroundColor: '#FFF',
			width: '100%',
			maxHeight: 170,
			position: 'absolute',
			top: 46,

			borderColor: 'rgba(0, 0, 0, 0.15)',
			borderWidth: 1,
			borderTopWidth: 0,

			borderBottomLeftRadius: 5,
			borderBottomRightRadius: 5,
		},

		inputContainer: {
			height: 50,
			width: '100%',
			borderColor: 'rgba(0, 0, 0, 0.15)',
			borderWidth: 1,
			borderRadius: 5,
			flexDirection: 'row',
			alignItems: 'center',
			padding: 10,
		},
		input: {
			flexGrow: 1,
			height: '100%',
			marginRight: 5,
			color: '#000',
		},

		rangeSpacer: {
			backgroundColor: colors.textGrey,
			height: 2,
			flexGrow: 1,
			marginLeft: 5,
			marginRight: 5,
		},
		rangeContainer: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			paddingBottom: 6,
		},
		circleBtnSelected: {
			backgroundColor: colors.orange,
			borderWidth: 0,
		},
		circleBtnTextSelected: {
			color: colors.white,
		},
		circleBtnText: {
			fontSize: 15,
			color: colors.textGrey,
		},
		circleBtn: {
			borderWidth: 2,
			borderColor: colors.textGrey,

			alignItems: 'center',
			justifyContent: 'center',
			borderRadius: 15,
			padding: 5,
			paddingLeft: 6,
			paddingRight: 6,
			minWidth: 30,
			//height: 30,
		},
		title: {
			fontSize: 17,
			color: colors.textGrey,
			paddingBottom: 10,
			flexGrow: 1,
		},
		desc: {
			fontSize: 17,
			paddingBottom: 15,
		},
		container: {
			marginTop: 25,
		},
		box: {
			// position: "absolute",
			width: '90%',
			borderRadius: 15,
			marginLeft: '5%',
			backgroundColor: '#FFF',
			padding: 15,
			marginTop: 20,
		},
	})
);
