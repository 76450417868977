import { IS_NOVOTI_APP } from '../MultiAppSettings';

let defaultColors = {
	borderColor: '#A09FB0',
	bgWhite: '#FFFFFF',
	bgOrange: '#f8a21c',
	bgLightOrange: '#e74c3c',
	alternativeDarkBlueText: '#4481eb',
	alternativeRed: '#e74c3c',
	alternativeGreen: '#2ecc71',
	questionBorder: '#e74c3c',
	signOutBtn: '#e74c3c',
	//

	black: '#232323',
	grey: '#4c4c4c',
	lightGrey: '#8c8c8c',

	bgGrey: '#ECECF3',
	textGrey: '#A09FB0',

	orange: '#f8a21c',
	darkOrange: '#d98300',
	lightOrange: '#fec440',
	green: '#2ecc71',
	red: '#e74c3c',

	lightBlue: '#00e4ff',
	darkBlue: '#4481eb',

	otherWhite: '#ececf3',
	white: '#fff',

	bottomMenuBackground: '#fff',
	bottomMenuActive: 'tomato',
	bottomMenuInactive: 'gray',
};

let customColors = {};

const novotiColors = {
	// Util colors
	bgWhite: '#141ee3',

	bgOrange: '#201A92',
	bgLightOrange: '#141ee3',
	borderColor: '#86ACB0',
	alternativeDarkBlueText: '#ffffff',
	alternativeRed: '#9ec7cb',
	alternativeGreen: '#86ACB0',
	questionBorder: '#86ACB0',
	signOutBtn: '#000000',

	///
	lightBlue: '#141ee3',
	darkBlue: '#201a92',

	orange: '#86ACB0',
	lightOrange: '#9ec7cb',
	bottomMenuBackground: '#201a92',
	bottomMenuActive: '#FFF',
	bottomMenuInactive: '#8982ff',
};

export const appColors = IS_NOVOTI_APP
	? {
			...defaultColors,
			...novotiColors,
			...customColors,
	  }
	: defaultColors;

export function setColors(colors) {
	customColors = {
		...customColors,
		...colors,
	};
	if (IS_NOVOTI_APP) {
		customColors = {
			...customColors,
			...novotiColors,
		};
	}
}

export function useGetColors() {
	console.log('Get colors', IS_NOVOTI_APP);
	return { ...appColors, ...customColors };
}

export function useColors(func: (appColors: typeof defaultColors) => any) {
	return func({
		...appColors,
		...customColors,
	});
}
