import React, { useState } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Image, Animated, ActivityIndicator, Easing } from 'react-native';
import { FontAwesome as Icon } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';

import helpers from '../../helpers';

interface Props {
	onPress: (func: (btn: any) => void) => void;
	width: number;
	title?: string;
}

let targWidth = 300;
const loadingWidth = 100;

const colors = helpers.appColors;

export default class AnimatedButton extends React.Component<
	Props,
	{
		width: Animated.Value;
		left: Animated.Value;
		opacity: Animated.Value;
		loadOpcatiy: Animated.Value;
	}
> {
	constructor(props: Props) {
		super(props);
		if (props.width) {
			targWidth = props.width;
		}
		this.state = {
			width: new Animated.Value(targWidth),
			left: new Animated.Value(0),

			opacity: new Animated.Value(1),
			loadOpcatiy: new Animated.Value(0),
		};
	}

	startLoad() {
		Animated.parallel([
			Animated.timing(this.state.width, {
				toValue: loadingWidth,
				duration: 350,
				easing: Easing.cubic,
			}),
			Animated.timing(this.state.left, {
				toValue: (targWidth - loadingWidth) / 2,
				duration: 350,
				easing: Easing.cubic,
			}),
			Animated.timing(this.state.opacity, {
				toValue: 0,
				duration: 150,
			}),
			Animated.sequence([
				Animated.delay(300),
				Animated.timing(this.state.loadOpcatiy, {
					toValue: 1,
					duration: 200,
				}),
			]),
		]).start();
		setTimeout(
			() =>
				requestAnimationFrame(() => {
					this.props.onPress();
					setTimeout(() => this.stopLoad(), 500);
				}),
			100
		);
	}
	stopLoad() {
		Animated.parallel([
			Animated.timing(this.state.width, {
				easing: Easing.cubic,
				toValue: targWidth,
			}),
			Animated.timing(this.state.left, {
				easing: Easing.cubic,
				toValue: 0,
			}),
			Animated.timing(this.state.loadOpcatiy, {
				toValue: 0,
			}),
			Animated.sequence([
				Animated.delay(300),
				Animated.timing(this.state.opacity, {
					toValue: 1,
				}),
			]),
		]).start();
	}

	render() {
		return (
			<View style={styles.btnContainer}>
				<TouchableOpacity onPress={() => this.startLoad()} activeOpacity={0.65}>
					<Animated.View style={{ width: this.state.width, left: this.state.left }}>
						<LinearGradient colors={[colors.lightOrange, colors.orange]} style={styles.btn} start={[0, 0]} end={[1, 1]}>
							<Animated.View style={{ opacity: this.state.opacity, position: 'absolute' }}>
								<Text style={styles.btnText}>{this.props.title || 'Sign in'}</Text>
							</Animated.View>
							<Animated.View style={{ opacity: this.state.loadOpcatiy, ...styles.animatedBtn }}>
								<ActivityIndicator color={helpers.appColors.white} size="large" />
							</Animated.View>
						</LinearGradient>
					</Animated.View>
				</TouchableOpacity>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	animatedBtn: {
		position: 'absolute',
		backgroundColor: 'rgba(0, 0, 0, 0.1)',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 15,
	},
	btnContainer: {
		shadowColor: colors.orange,
		shadowOffset: {
			width: 0,
			height: 0,
		},
		shadowOpacity: 0.3,
		shadowRadius: 16.0,
		// height: 700,
	},
	btnText: {
		fontSize: 18,
		color: '#FFF',
	},
	btn: {
		width: '100%',
		height: 60,
		marginTop: 40,
		borderRadius: 15,

		justifyContent: 'center',
		alignItems: 'center',
	},
});
