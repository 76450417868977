import React, { useState, useEffect, useRef } from 'react';
import { Text, View, TouchableOpacity, Dimensions, Platform } from 'react-native';
import { Camera } from 'expo-camera';

import WindowDimensions from './classes/Dimensions';
const { width, height } = WindowDimensions;
const IS_IOS = Platform.OS === 'ios';

export default function CamPage(props) {
	const [hasPermission, setHasPermission] = useState(null);
	// const [type, setType] = useState(!props.type || props.type === "back" ? Camera.Constants.Type.back : Camera.Constants.Type.front);
	const type = !props.type || props.type === 'back' ? Camera.Constants.Type.back : Camera.Constants.Type.front;
	const camRef = useRef(false);

	useEffect(() => {
		(async () => {
			const { status } = await Camera.requestCameraPermissionsAsync();
			setHasPermission(status === 'granted');
		})();
	}, []);

	if (hasPermission === null) {
		return <View />;
	}
	if (hasPermission === false) {
		return <Text>No access to camera</Text>;
	}
	return (
		<View style={{ height: height * 0.95, backgroundColor: 'red' }}>
			<Camera style={{ flex: 1 }} type={type} ref={camRef}>
				{/* <View
            style={{
              flex: 1,
              backgroundColor: 'transparent',
              flexDirection: 'row',
            }}>
            <TouchableOpacity
              style={{
                flex: 0.1,
                alignSelf: 'flex-end',
                alignItems: 'center',
              }}
              onPress={() => {
                setType(
                  type === Camera.Constants.Type.back
                    ? Camera.Constants.Type.front
                    : Camera.Constants.Type.back
                );
              }}>
              <Text style={{ fontSize: 18, marginBottom: 10, color: 'white' }}> Flip </Text>
            </TouchableOpacity>
          </View> */}
				<View style={{ width: '100%', height: '90%', justifyContent: 'flex-end', alignItems: 'center' }}>
					<TouchableOpacity
						style={{
							borderWidth: 2,
							borderColor: '#FFF',
							borderRadius: 40,
							justifyContent: 'center',
							alignItems: 'center',

							height: 80,
							width: 80,
						}}
						onPress={() => {
							// setType(
							//   type === Camera.Constants.Type.back
							//     ? Camera.Constants.Type.front
							//     : Camera.Constants.Type.back
							// );
							if (camRef) {
								if (IS_IOS) {
									camRef.current.pausePreview();
								}
								camRef.current
									.takePictureAsync({
										// mediaTypes: ImagePicker.MediaTypeOptions.All,
										allowsEditing: false,
										base64: true,
										aspect: [4, 3],
										quality: 0.7,
									})
									.then(d => {
										if (IS_IOS) {
											camRef.current.pausePreview();
										} else {
											camRef.current.resumePreview();
										}
										// camRef.current.resumePreview();
										props.hide(d);
									});
							}
						}}>
						<View style={{ width: 50, height: 50, borderRadius: 25, backgroundColor: 'white', opacity: 0.7 }} />
						{/* <Text style={{ fontSize: 18, color: 'white' }}> Flip </Text> */}
					</TouchableOpacity>
				</View>
			</Camera>
		</View>
	);
}
