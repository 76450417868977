import React, { useState } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, ScrollView, Image, Button, ActivityIndicator } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { FontAwesome as Icon, Ionicons } from '@expo/vector-icons';

import { article, baseProps } from './classes/questionTemplate';
import Article from './UI/Article';
import GradientHeader from './UI/GradientHeader';
import helpers, { query, api } from '../helpers';
import WindowDimensions from './classes/Dimensions';

const colors = helpers.appColors;
const { width, height } = WindowDimensions;

interface props extends baseProps {}

interface state {
	articles: article[];
	loading: boolean;
}

export default class NewsPage extends React.Component<props, state> {
	state = {
		loading: true,
		articles: [
			{
				title: 'Title',
				image:
					'https://images.pexels.com/photos/585419/pexels-photo-585419.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
				views: 10,
				read: false,
				text: 'Bla bla bla',
				unix: new Date(),
			},
		],
	};

	componentWillMount() {
		this.syncData();
	}

	componentDidMount() {
		this.getDB();
	}

	async syncData() {
		let data = await api('news', null, this.props.userData.token);
	}

	async getDB() {
		let data = await query('SELECT * FROM news ORDER BY id DESC LIMIT 20', this.props.db, []);
		if (data) {
			// console.log("GOt data from db", data.rows);
		}
	}

	openArticle(i: number) {
		const target: article = this.state.articles[i];
		this.props.showModal(
			<Article data={target} />,
			<Article data={target} onlyHeader={true}>
				<TouchableOpacity onPress={() => this.props.showModal(null, null, false)} style={styles.articleClose}>
					<Ionicons name={'md-close'} size={28} color="#FFF" />
				</TouchableOpacity>
			</Article>
		);
	}

	render() {
		return (
			<View>
				<GradientHeader headerTitle="Nyheder" points={this.props.points} userData={this.props.userData} />
				<ScrollView style={styles.newsContainer} contentInset={{ bottom: 150 }}>
					{this.state.loading && (
						<View style={{ padding: 20 }}>
							<ActivityIndicator size="large" />
						</View>
					)}
					{this.state.articles.map((article, i) => (
						<TouchableOpacity
							onPress={() => this.openArticle(i)}
							style={[styles.news, styles.container]}
							activeOpacity={0.8}
							key={i}>
							<Image style={styles.newsBg} source={{ uri: article.image }} />
							<View style={styles.bgOverlay} />
							<LinearGradient colors={[colors.lightBlue, colors.darkBlue]} style={styles.bgOverlay} />
							<View style={styles.newsContent}>
								{article.read && (
									<View style={styles.newsBagde}>
										<Text style={styles.bagdeText}>ULÆST</Text>
									</View>
								)}
								<View style={styles.newsBottomPart}>
									<Text style={[styles.newsTitle, styles.shadow]}>{article.title}</Text>
									<Text style={[styles.newsDesc, styles.shadow]}>{article.text.substring(0, 20)}</Text>
								</View>
								<View style={[helpers.appStyles.inlineFlex, styles.infoContainer]}>
									<View style={helpers.appStyles.inlineFlex}>
										<Icon name={'eye'} size={12} color="#FFF" />
										<Text style={styles.readersText}>{article.views}</Text>
									</View>
									<Text style={styles.dateText}>{article.unix.toDateString()}</Text>
								</View>
							</View>
						</TouchableOpacity>
					))}
				</ScrollView>
			</View>
		);
	}
}

// export  function NewsPagez(props) {
//     const [currReading, setReading] = useState(false);
//     const isReading: boolean = typeof currReading === "number";
//     let temp = null;
//     let articleCloser = (a) => {};
//     const closerCallback = () => setReading(false);
//     const images = [
//         "https://images.pexels.com/photos/585419/pexels-photo-585419.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//         "https://images.pexels.com/photos/1216544/pexels-photo-1216544.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//         "https://images.pexels.com/photos/374079/pexels-photo-374079.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//         "https://images.pexels.com/photos/3184418/pexels-photo-3184418.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//         "https://images.pexels.com/photos/2219024/pexels-photo-2219024.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
//     ];

//     const openArticle = (i:number) => {
//         props.showModal(
//             <Article img={images[i]}/>,
//             <Article img={images[i]} onlyHeader={true}>
//                 <TouchableOpacity  onPress={() => props.showModal(null, null, false)} style={styles.articleClose}>
//                     <Ionicons name={"md-close"} size={28} color="#FFF" />
//                 </TouchableOpacity>
//             </Article>
//         );
//     };
//   return (<View>
//       <GradientHeader headerTitle="Nyheder"/>
//       <ScrollView style={styles.newsContainer} contentInset={{bottom: 150}}>
//         {
//             [...Array(5)].map((d, i) =>
//             <TouchableOpacity onPress={() => openArticle(i)} style={[styles.news, styles.container]} activeOpacity={0.8} key={i}>
//                 <Image
//                 style={styles.newsBg}
//                 source={{uri: images[i]}} />
//                 <View style={styles.bgOverlay} />
//                 <LinearGradient
//                     colors={[colors.lightBlue, colors.darkBlue]}
//                     style={styles.bgOverlay}
//                 />
//                 <View style={styles.newsContent}>
//                     <View style={styles.newsBagde}>
//                         <Text style={styles.bagdeText}>
//                             ULÆST
//                         </Text>
//                     </View>
//                     <View style={styles.newsBottomPart}>
//                         <Text style={[styles.newsTitle, styles.shadow]}>
//                             Titel
//                         </Text>
//                         <Text style={[styles.newsDesc, styles.shadow]}>
//                             Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
//                         </Text>
//                     </View>
//                     <View style={[helpers.appStyles.inlineFlex, styles.infoContainer]}>
//                         <View style={helpers.appStyles.inlineFlex}>
//                             <Icon name={"eye"} size={12} color="#FFF" />
//                             <Text style={styles.readersText}>907</Text>
//                         </View>
//                         <Text style={styles.dateText}>Today, 5:00 PM</Text>
//                     </View>
//                 </View>
//             </TouchableOpacity>)
//         }
//     </ScrollView>
//   </View>);
// }

const styles = StyleSheet.create({
	articleClose: {
		position: 'absolute',
		right: 10,
		width: 45,
		height: 45,
		zIndex: 99999,

		justifyContent: 'center',
		alignItems: 'center',
	},
	infoContainer: {
		position: 'absolute',
		bottom: 13,
		left: 20,
		width: '100%',
	},
	newsBottomPart: {
		top: 80,
	},
	newsDesc: {
		fontSize: 11,
		color: colors.white,
	},
	shadow: {
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 0,
		},
		shadowOpacity: 0.5,
		shadowRadius: 5,
	},
	newsTitle: {
		fontSize: 18,
		color: colors.white,
		fontWeight: '500',
	},
	readIcon: {
		height: 10,
		width: 16,
		backgroundColor: 'yellow',
	},
	readersText: {
		fontSize: 11,
		color: colors.white,
		paddingLeft: 4,
	},
	dateText: {
		fontSize: 11,
		color: colors.white,
		position: 'absolute',
		right: 0,
	},
	newsBagde: {
		height: 18,
		width: 43,
		backgroundColor: 'red',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 25,
		position: 'absolute',
		right: 10,
		top: 10,
	},
	bagdeText: {
		color: colors.white,
		fontSize: 9,
	},
	newsContent: {
		position: 'absolute',
		height: '100%',
		width: '100%',
		padding: 20,
		left: 15,
	},
	bgOverlay: {
		height: '100%',
		width: '100%',
		opacity: 0.5,
		//backgroundColor: "rgba(0,0,0, 0.3)",
		position: 'absolute',
		left: 15,
	},
	newsBg: {
		width: '100%',
		height: '100%',
	},
	news: {
		height: 200,
		width: '100%',
		marginTop: 10,
		marginBottom: 10,

		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.2,
		shadowRadius: 5,
	},
	newsContainer: {
		width: '100%',
		height: '100%',
	},
	title: {
		fontSize: 33,
		color: colors.white,
	},
	bg: {
		height: '100%',
		width: '100%',

		paddingTop: 40,
	},
	container: {
		paddingLeft: 15,
		paddingRight: 15,
	},
});
