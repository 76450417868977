import React, { Component } from 'react';
import { StyleSheet, Text, Animated, View, SectionList, TextInput, TouchableOpacity } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { FontAwesome as Icon } from '@expo/vector-icons';
import { BlurView } from 'expo-blur';
import WindowDimensions from '../classes/Dimensions';

import Lang from '../locale';

import { appColors, useColors } from '../../multiApp';

const { width, height } = WindowDimensions;
const colors = appColors;

interface Props {
	show: boolean;
	multi: boolean;
	data: [string];
	hide: (data: any) => void;
}

interface State {
	show: boolean;
	data: any;
	searchData: any;
}

export default class SelectModal extends Component<Props, State> {
	animateBg: Animated.Value = new Animated.Value(0);
	animatePos: Animated.Value = new Animated.Value(height);

	constructor(props: Props) {
		super(props);
		this.state = {
			show: props.show,
			data: props.data,
			searchData: props.data,
		};
	}

	componentDidMount() {
		if (this.state.show) {
			this.showAnimation();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.show !== this.props.show) {
			if (this.props.show) {
				this.setState({
					show: this.props.show,
				});
			} else {
				this.hideAnimation();
			}
		} else if (prevState.show !== this.state.show && this.state.show) {
			this.showAnimation();
		} else if (prevState.data.length !== this.state.data.length) {
			this.setState({
				show: this.props.data,
				searchData: this.props.data,
			});
		}
		// else if(prevState.show !== this.state.show && this.state.show)
		// {
		//     this.showAnimation();
		// }
	}

	recursiveSearch(dataset, term) {
		if (dataset.children && dataset.children.length > 0) {
			let resp = dataset.children.reduce((acc, curr) => [...acc, ...this.recursiveSearch(curr, term)], []);
			dataset.children = resp;
			return [dataset];
		} else {
			return dataset.question.toLowerCase().includes(term.toLowerCase()) ? [dataset] : [];
		}
	}

	search(term: string) {
		let resp = [...this.state.data];
		if (term.length > 0) {
			resp = this.state.data
				.map(d => this.recursiveSearch({ ...d }, term))
				.reduce((acc, curr) => [...acc, ...curr], []);
		}

		this.setState({
			searchData: resp,
		});
	}

	showAnimation = () => {
		Animated.parallel([
			Animated.timing(this.animateBg, {
				toValue: 1,
				duration: 250,
				useNativeDriver: false,
			}),
			Animated.timing(this.animatePos, {
				toValue: 0,
				duration: 250,
			}),
		]).start();
	};

	hideAnimation = () => {
		Animated.parallel([
			Animated.timing(this.animateBg, {
				toValue: 0,
				duration: 250,
				useNativeDriver: false,
			}),
			Animated.timing(this.animatePos, {
				toValue: height,
				duration: 250,
				useNativeDriver: false,
			}),
		]).start(() => this.close());
	};

	close = () => {
		this.setState({
			show: false,
		});
		this.props.hide(this.state.data);
	};

	render() {
		const styles = getStyles();
		let data = this.state.data;
		let isCats = !Array.isArray(data);
		const bgColor = this.animateBg.interpolate({
			inputRange: [0, 1],
			outputRange: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0.8)'],
		});

		//getTranslateTransform()
		const hasSelections: boolean = this.state.data.some(section => {
			return section.response.length > 0; //.children.some(d => d.);
		});

		return this.state.show ? (
			<Animated.View
				style={[
					styles.bg,
					{
						backgroundColor: bgColor,
					},
				]}>
				<Animated.View style={[styles.container, { top: this.animatePos }]}>
					<View style={{ width: '100%', flexDirection: 'row' }}>
						<View style={[styles.topContainer, hasSelections || 1 ? { paddingRight: 0 } : {}]}>
							<View style={[styles.inputContainer, { flexGrow: 1 }]}>
								<TextInput
									style={styles.input}
									placeholder={Lang.t('Indtast søgeord')}
									onChange={e => this.search(e.nativeEvent.text)}
								/>
								<Icon name="search" style={styles.searchIcon} size={22} color="#000" />
							</View>
							<LinearGradient
								start={[0, 0]}
								colors={[appColors.bgOrange, appColors.alternativeRed]}
								style={styles.suggestionGradient}
							/>
						</View>

						{hasSelections ? (
							<TouchableOpacity
								onPress={this.hideAnimation}
								style={{ width: 60, justifyContent: 'center', alignItems: 'center', paddingRight: 5 }}>
								<Text style={{ color: colors.darkBlue, fontWeight: 'bold' }}>{Lang.t('Gem')}</Text>
							</TouchableOpacity>
						) : (
							<TouchableOpacity
								onPress={this.hideAnimation}
								style={{ width: 60, justifyContent: 'center', alignItems: 'center', paddingRight: 5 }}>
								<Text style={{ color: colors.darkBlue, fontWeight: 'bold' }}>{Lang.t('Luk')}</Text>
							</TouchableOpacity>
						)}
					</View>
					<SectionList
						sections={this.state.searchData.map((question, i) => ({
							i,
							title: question.question,
							response: question.response,
							data: question.children, //.map(d => d.question),
						}))}
						keyExtractor={(item, index) => item + index}
						renderItem={({ item, index, section }) => (
							<TouchableOpacity
								onPress={() => {
									let newData = [...this.state.data];
									if (!this.props.multi) {
										for (let i = 0; i < newData.length; i++) {
											newData[i].response = [];
										}
										newData[section.i].response = [item.questionID];
									} else {
										if (newData[section.i].response.includes(item.questionID)) {
											newData[section.i].response = newData[section.i].response.filter(d => d !== item.questionID);
										} else {
											newData[section.i].response.push(item.questionID);
										}
									}

									this.setState({
										data: newData,
									});
									if (!this.props.multi) {
										requestAnimationFrame(() => this.hideAnimation());
									}
								}}
								style={styles.item}>
								<Text style={styles.title}>{Lang.t(item.question)}</Text>
								{section.response.includes(item.questionID) ? (
									<Icon name="check" style={styles.searchIcon} size={15} color="#000" />
								) : (
									item.secondaryQuestion && <Text style={styles.title}>{Lang.t(item.secondaryQuestion)}</Text>
								)}
							</TouchableOpacity>
						)}
						renderSectionHeader={({ section: { title, data } }) =>
							data.length > 0 && (
								<View style={styles.headerView}>
									<Text style={styles.header}>{Lang.t(title)}</Text>
								</View>
							)
						}
					/>
				</Animated.View>
			</Animated.View>
		) : null;
	}
}
/*

export default function SelectModal(this.props){
    let data = props.data || [];
    let isCats = !Array.isArray(data);
    return state.show ? (<View style={styles.bg}>
        <View style={styles.container}>
            <View style={styles.topContainer}>
                <View style={styles.inputContainer}>
                    <TextInput style={styles.input} placeholder="Indtast søgeord.." />
                    <Icon name="search" style={styles.searchIcon} size={22} color="#000" />
                </View>
                <LinearGradient start={[0,0]} colors={[colors.orange, colors.red]} style={styles.suggestionGradient} />
            </View>
            <SectionList
                sections={[
                    {
                    title: "Main dishes",
                    data: ["Pizza", "Burger", "Risotto"]
                    },
                    {
                    title: "Sides",
                    data: ["French Fries", "Onion Rings", "Fried Shrimps"]
                    },
                    {
                    title: "Drinks",
                    data: ["Water", "Coke", "Beer"]
                    },
                    {
                    title: "Desserts",
                    data: ["Cheese Cake", "Ice Cream"]
                    }
                ]}
                keyExtractor={(item, index) => item + index}
                renderItem={({ item }) => <View style={styles.item}>
                    <Text style={styles.title}>{item}</Text>
                    <Icon name="check" style={styles.searchIcon} size={15} color="#000" />
                </View>}
                renderSectionHeader={({ section: { title } }) => (
                    <View style={styles.headerView}>
                        <Text style={styles.header}>{title}</Text>
                    </View>
                )}
            />
        </View>
    </View>) : null;
};*/

const getStyles = () =>
	useColors(colors =>
		StyleSheet.create({
			suggestionGradient: {
				width: '100%',
				height: 3,
			},
			item: {
				height: 50,
				width: '100%',
				backgroundColor: 'rgba(0, 0, 0, 0)',
				borderBottomColor: '#e6e6e6',
				borderBottomWidth: 1,
				paddingLeft: 15,
				paddingRight: 15,

				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
			title: {
				color: '#000',
			},
			headerView: {
				height: 30,
				width: '100%',
				justifyContent: 'center',
				backgroundColor: '#ebebeb',
				paddingLeft: 10,
			},
			header: {
				color: '#000',
				fontWeight: 'bold',
			},

			bg: {
				position: 'absolute',
				top: 0,
				left: 0,
				zIndex: 99999,
				height: '100%',
				width: '100%',
				backgroundColor: 'rgba(0, 0, 0, 0.5)',

				justifyContent: 'flex-end',
				alignItems: 'center',
			},
			inputContainer: {
				borderWidth: 1,
				borderRadius: 5,
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0,
				//   width: "100%",
				flexDirection: 'row',
				alignItems: 'center',
				backgroundColor: '#FFF',
				borderColor: '#ededed',
			},
			topContainer: {
				//   width: "100%",
				flexGrow: 1,
				alignItems: 'center',

				paddingLeft: 15,
				paddingRight: 15,
				paddingTop: 10,
				paddingBottom: 10,

				backgroundColor: '#FFF',

				shadowColor: '#000',
				shadowOffset: {
					width: 0,
					height: 5,
				},
				shadowOpacity: 0.05,
				shadowRadius: 10,
				zIndex: 99,
			},
			input: {
				flexGrow: 1,
				paddingLeft: 12,
				height: 45,
			},
			searchIcon: {
				paddingLeft: 10,
				paddingRight: 12,
			},
			container: {
				backgroundColor: '#fff',
				height: '75%',
				width: '100%',
			},
		})
	);
