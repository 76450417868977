import React, { useState, useRef } from 'react';
import { StyleSheet, Text, View, Alert, Animated, TouchableOpacity, Image, PanResponder, Easing } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Modalize } from 'react-native-modalize';
import { FontAwesome as Icon } from '@expo/vector-icons';

import Lang from './locale';

import Svg, { LinearGradient as LG, Text as TextSVG, Defs, Stop, TSpan } from 'react-native-svg';

import CreditsAnimator from './UI/CreditsAnimator';
import QuestionBox from './UI/QuestionBox';

import { sheetGroup, questionJSONTemplate } from './classes/questionTemplate';
import helpers, { menus, appStyles, api, query, findPath, IP } from '../helpers';
import { RandomQuotes, appColors } from '../multiApp';
import { ALERT_TITLE } from '../MultiAppSettings';

import WindowDimensions from './classes/Dimensions';
const { width, height } = WindowDimensions;

function randPos(centerX: number, centerY: number, radius: number = 50) {
	let pt_angle = Math.random() * 2 * Math.PI;
	let pt_radius_sq = Math.random() * radius * radius;
	let pt_x = Math.sqrt(pt_radius_sq) * Math.cos(pt_angle);
	let pt_y = Math.sqrt(pt_radius_sq) * Math.sin(pt_angle);

	return {
		x: centerX + pt_x,
		y: centerY + pt_y,
	};
}

let doAnimation: boolean = true;

interface Props {
	groups: sheetGroup[];
	rights: string[];
	localeKey: number;
	frontpage: string;
}

interface quizQuestion extends questionJSONTemplate {
	reason: string;
}

interface quest {
	id: number;
	reward: number;
	type: 'sheet' | 'news';
	typeID?: number;

	n: number;
	nType: 'day' | 'week' | 'month';
}

interface State {
	showStreak: boolean;
	showDaily: boolean;
	dailyCorrect: boolean;
	newCredits: number;
	credits: number;
	streakAmount: number;
	streakPoints: number;
	showQoutes: boolean;
	hasRedirected: boolean;
	quiz: quizQuestion | false;
	quests: quest[];
}

// showStreak: true,
// newCredits: 0,
// credits: 100,

// showDaily: true,
// dailyCorrect: false,

export default class Dashboard extends React.Component<Props, State> {
	myRefs = {
		modal: null,
	};
	pointAnimator = null;

	leftAnim: Animated.Value;
	rightAnim: Animated.Value;
	opacity: Animated.Value;
	topAnim: Animated.Value;
	zoom: Animated.Value;
	borderAnim: Animated.Value;

	hasRedirected = false;

	constructor(props) {
		super(props);
		const w: number = doAnimation ? width / 1.5 : 0;
		this.leftAnim = new Animated.Value(w * -1);
		this.rightAnim = new Animated.Value(w);
		this.opacity = new Animated.Value(doAnimation ? 0 : 1);

		this.topAnim = new Animated.Value(0);
		this.zoom = new Animated.Value(1);

		this.borderAnim = new Animated.Value(1);

		this.state = {
			showStreak: false,
			streakAmount: 0,
			streakPoints: 0,

			newCredits: 0,
			credits: 100,

			showDaily: true,
			dailyCorrect: false,
			quiz: false,
			showQoutes: false,
			quests: [],
			hasRedirected: false,
		};
	}

	componentDidUpdate(props: Props) {
		if (!this.hasRedirected && props.frontpage !== this.props.frontpage) {
			this.redirect();
		}
	}

	tries = 0;
	redirect() {
		// if (!this.hasRedirected && tries < 10) {
		// 	try {
		// 		this.props.navigation.navigate(this.props.frontpage);
		// 		this.hasRedirected = true;
		// 	} catch (error) {
		// 		setTimeout(() => this.redirect(), 50);
		// 	}
		// }
	}

	async componentDidMount() {
		if (!this.hasRedirected && this.props.frontpage !== 'Dashboard') {
			setTimeout(() => this.redirect(), 500);
		}

		if (this.props.userData?.token) {
			const stamp = new Date();
			const day = stamp.getDate() < 10 ? `0${stamp.getDate()}` : stamp.getDate();
			const month = stamp.getMonth() < 10 ? `0${stamp.getMonth() + 1}` : stamp.getMonth() + 1;
			const startOfDay = new Date(`${month}/${day}/${stamp.getFullYear()}`);
			const endOfDay = new Date(`${month}/${day}/${stamp.getFullYear()} 23:59:59`);

			let quizData = await query('SELECT * FROM quiz WHERE stamp >= ? AND stamp <= ?', this.props.db, [
				startOfDay.getTime().toString(),
				endOfDay.getTime().toString(),
			]);
			if (quizData.length > 0) {
				const data = JSON.parse(quizData[0].content);
				this.setState({
					dailyCorrect: data.correct,
					showDaily: false,
					quiz: data.quiz,
				});
			}

			api('dashboard', {}, this.props.userData.token).then(d => {
				if (d) {
					if (d.data.streak && d.data.streakPoints > 0) {
						this.setState({
							streakAmount: d.data.streak,
							streakPoints: d.data.streakPoints,
							showStreak: true,
						});
					}

					if (d.data.quiz && !this.state.quiz) {
						this.setState({
							quiz: d.data.quiz,
						});
					}

					if (d.data.quests) {
						this.setState({
							quests: d.data.quests,
						});
					}
				}
			});
		}

		Animated.parallel([
			Animated.timing(this.leftAnim, {
				toValue: 0,
				easing: Easing.elastic(1),
				duration: 400,
				useNativeDriver: true,
			}),
			Animated.timing(this.rightAnim, {
				toValue: 0,
				easing: Easing.elastic(1),
				duration: 300,
				useNativeDriver: true,
			}),
		]).start();

		Animated.loop(
			Animated.sequence([
				Animated.timing(this.borderAnim, {
					duration: 800,
					toValue: 0.98,
					useNativeDriver: true,
				}),
				Animated.timing(this.borderAnim, {
					duration: 800,
					toValue: 1,
					useNativeDriver: true,
				}),
			])
		).start();
		this.props.navigation.addListener('focus', e => {
			this.myRefs.modal?.open();
			Animated.parallel([
				// Animated.timing(this.zoom, {
				//     duration: 100,
				//     toValue: 1,
				//     useNativeDriver: false,
				// }),
				// Animated.timing(this.topAnim, {
				//     duration: 100,
				//     toValue: 0,

				//     useNativeDriver: false,
				// }),
				Animated.timing(this.leftAnim, {
					duration: 100,
					toValue: 0,
					useNativeDriver: true,
				}),
				Animated.timing(this.rightAnim, {
					duration: 100,
					toValue: 0,
					useNativeDriver: true,
				}),
			]).start();
			this.verifyCheck();
		});
		this.verifyCheck();
	}

	verifyCheck() {
		if (this.props.userData.verifyData) {
			Alert.alert(ALERT_TITLE, Lang.t('VERIFY_USERDATA'));
			this.props.navigation.jumpTo('Settings');
		}
		// }else{
		//     this.props.requestNotifications();
		// }
		this.props.requestNotifications();
	}

	navigate = path => {
		const duration = 100;
		this.myRefs.modal?.close();
		Animated.parallel([
			// Animated.timing(this.zoom, {
			//     duration,
			//     toValue: 0.6,
			//     useNativeDriver: false,
			// }),
			// Animated.timing(this.topAnim, {
			//     duration,
			//     toValue: -24,
			//     useNativeDriver: false,
			// }),
			Animated.timing(this.leftAnim, {
				duration,
				toValue: -45,
				useNativeDriver: true,
			}),
			Animated.timing(this.rightAnim, {
				duration,
				toValue: 10,
				useNativeDriver: true,
			}),
		]).start(() => this.props.navigation.navigate(path));
		// setTimeout(() => this.props.navigation.navigate(path), duration);
	};

	render() {
		// const appColors = {
		// 	...appColors,
		// 	...this.props.userData.colors,
		// };
		const colors = appColors;
		return (
			<LinearGradient
				start={[0, 0]}
				end={[0, 0.3]}
				colors={[appColors.bgOrange, appColors.alternativeRed]}
				style={{ height: '100%', width: '100%' }}>
				<View style={[styles.topContainer, helpers.appStyles.inlineFlex]} key={this.props.localeKey}>
					<Animated.View
						style={[
							styles.avatarContainer,
							helpers.appStyles.inlineFlex,
							{
								transform: [
									{ translateX: this.leftAnim },
									{ scaleX: this.zoom },
									{ scaleY: this.zoom },
									{ translateY: this.topAnim },
								],
							},
						]}>
						<Image
							style={styles.avatar}
							resizeMode="cover"
							source={
								this.props.userData.pb
									? { uri: `${IP}/image/${this.props.userData.pb}` }
									: require('../assets/avatar.png')
							}
						/>
						<Text style={styles.nameText}>{this.props.userData.name}</Text>
					</Animated.View>

					<Animated.View
						style={[
							styles.creditsContainer,
							helpers.appStyles.inlineFlex,
							{
								transform: [
									{ translateX: this.rightAnim },
									{ scaleX: this.zoom },
									{ scaleY: this.zoom },
									{ translateY: this.topAnim },
								],
							},
						]}>
						<Text style={styles.creditsText}>{this.props.points}</Text>

						<View style={[styles.animatedCredits, styles.credits, { position: undefined }]} />
						<CreditsAnimator animator={func => (this.pointAnimator = func)} callback={(adder: number) => {}} />
					</Animated.View>
				</View>
				<Modalize
					ref={e => (this.myRefs.modal = e)}
					alwaysOpen={height * 0.85}
					modalHeight={height * 0.85}
					modalStyle={{ backgroundColor: colors.white }}
					overlayStyle={{ backgroundColor: 'transparent' }}>
					<View style={styles.iconContainer}>
						{this.props.groups
							.filter(group => group.sheets.length > 0)
							.map((d, i, arr) => (
								<TouchableOpacity
									style={[styles.button, i >= arr.length - 2 ? { marginBottom: 0 } : {}]}
									key={i}
									onPress={() => this.navigate(d.name)}>
									<Icon name={d.icon} size={50} color={helpers.appColors.grey} />

									<View style={styles.textContainer}>
										<Text style={styles.text}>{Lang.t(d.name)}</Text>
									</View>
								</TouchableOpacity>
							))}
						{menus
							.filter(d => d.slug !== 'Dashboard')
							.filter(d => this.props.rights.includes(d.slug))
							.map((d, i, arr) => (
								<TouchableOpacity
									style={[styles.button, i >= arr.length - 2 ? { marginBottom: 0 } : {}]}
									key={i}
									onPress={() => this.navigate(d.slug)}>
									<Icon name={d.icon} size={50} color={helpers.appColors.grey} />

									<View style={styles.textContainer}>
										<Text style={styles.text}>{Lang.t(d.name)}</Text>
									</View>
								</TouchableOpacity>
							))}
					</View>

					{this.state.quests.length > 0 && (
						<View style={{ paddingLeft: 15, paddingRight: 15 }}>
							<Text
								style={{ color: appColors.grey, fontSize: 17, fontWeight: 'bold', paddingBottom: 10, marginLeft: 3 }}>
								{Lang.t('Daglige handlinger')}
							</Text>
							{this.state.quests.map((d, key) => {
								const important = false;
								const path = findPath(d.type, d.typeID, this.props);
								return (
									path && (
										<Animated.View style={important ? { transform: [{ scale: this.borderAnim }] } : {}} key={key}>
											<TouchableOpacity
												onPress={() => this.navigate(path)}
												style={
													important
														? {}
														: {
																backgroundColor: appColors.bgGrey,
																width: '100%',
																height: 30,
																borderRadius: 4,
																marginTop: 5,
																transform: [{ scale: 0.99 }],
														  }
												}>
												<LinearGradient
													start={[0, 0]}
													end={[0, 2]}
													colors={important ? [colors.orange, colors.red] : [appColors.bgGrey, appColors.bgGrey]}
													style={{ backgroundColor: '#FFF', width: '100%', height: 30, borderRadius: 4 }}>
													<Animated.View style={[styles.taskRow]}>
														<View>
															<Text style={{ color: important ? appColors.white : appColors.grey, fontWeight: 'bold' }}>
																Udfyld observation
															</Text>
														</View>
														<View>
															<Text style={{ color: important ? appColors.white : appColors.grey }}>
																{d.reward} {Lang.t('point')}
															</Text>
														</View>
													</Animated.View>
												</LinearGradient>
											</TouchableOpacity>
										</Animated.View>
									)
								);
							})}

							{/* <TouchableOpacity
                        style={{backgroundColor: appColors.bgGrey, width: "100%", height: 30, borderRadius: 4, marginTop: 5, transform: [{scale: 0.99}]}}
                        onPress={() => this.navigate("News")}
                    >
                        <Animated.View style={[styles.taskRow]}>
                            <View>
                                <Text style={{color: appColors.grey, fontWeight: "bold"}}>Læs nyhed</Text>
                            </View>
                            <View>
                                <Text style={{color: appColors.grey}}>15 {Lang.t('point')}</Text>
                            </View>
                        </Animated.View>
                    </TouchableOpacity> */}
						</View>
					)}
					<View style={{ paddingLeft: 15, paddingRight: 15 }}>
						<RandomQuotes key={1} />
						{this.state.quiz && (
							<>
								<Text
									style={{
										color: appColors.grey,
										fontSize: 17,
										fontWeight: 'bold',
										marginLeft: 3,
										paddingBottom: 10,
										paddingTop: 28,
									}}>
									Popquiz
								</Text>
								<View>
									{!this.state.showDaily ? (
										<View style={{ marginBottom: 10 }}>
											{this.state.dailyCorrect ? (
												<Text style={{ color: appColors.green }}>Korrekt! Du har modtaget 10 {Lang.t('point')}.</Text>
											) : (
												<Text style={{ fontWeight: 'bold' }}>Det er ikke korrekt.</Text>
											)}
											<Text>{this.state.quiz.reason}</Text>
											<Text>Kom tilbage imorgen for et nyt spørgsmål.</Text>
										</View>
									) : null}
									<View>
										{!this.state.showDaily ? (
											this.state.dailyCorrect ? (
												<Icon
													name="check"
													size={32}
													style={{ position: 'absolute', right: 10 }}
													color={appColors.green}
												/>
											) : (
												<Icon
													name="times"
													size={32}
													style={{ position: 'absolute', right: 10 }}
													color={appColors.red}
												/>
											)
										) : null}

										<View
											style={{
												borderWidth: 2,
												borderColor: this.state.showDaily
													? appColors.bgGrey
													: this.state.dailyCorrect
													? appColors.green
													: appColors.red,
												padding: 10,
												borderRadius: 10,
												opacity: this.state.showDaily ? 1 : 0.4,
											}}
											pointerEvents={this.state.showDaily ? 'auto' : 'none'}>
											<QuestionBox
												onAnswer={async value => {
													api(
														'quizAnswer',
														{ answer: this.state.quiz.options[value] || '' },
														this.props.userData.token
													);
													const correct =
														this.state.quiz.options[value] &&
														this.state.quiz.options[value] === this.state.quiz.correct;
													this.setState({
														showDaily: false,
														dailyCorrect: correct,
													});
													if (correct) {
														const pointAmount = 15;
														await this.pointAnimator(pointAmount);
														this.props.setPoints(this.props.points + pointAmount);
														await query('TRUNCATE quiz;', this.props.db);
														await query('INSERT INTO quiz (stamp, content) VALUES (?, ?)', this.props.db, [
															Date.now().toString(),
															JSON.stringify({
																correct,
																quiz: this.state.quiz,
															}),
														]);
													}
												}}
												boxes={[]}
												onlyRaw={true}
												type={this.state.quiz.type}
												text={this.state.quiz.question ?? ''}
												answers={this.state.quiz.options ?? []}
											/>
										</View>
									</View>
								</View>
							</>
						)}
					</View>
				</Modalize>

				{this.state.showStreak ? (
					<View
						style={{
							backgroundColor: 'rgba(0, 0, 0, 0.5)',
							height: height,
							width: '100%',
							position: 'absolute',
							zIndex: 9999,
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<LinearGradient
							start={[0, 0]}
							colors={[colors.orange, colors.red]}
							style={{
								backgroundColor: '#FFF',
								width: '90%',
								height: 200,
								borderRadius: 20,
								justifyContent: 'space-evenly',
								alignItems: 'center',
							}}>
							<View
								style={{
									backgroundColor: '#FFF',
									width: '99%',
									height: '98%',
									borderRadius: 20,
									justifyContent: 'space-evenly',
									alignItems: 'center',
								}}>
								<Text style={{ fontSize: 30, fontWeight: 'bold', color: appColors.grey }}>Velkommen tilbage!</Text>
								<View style={appStyles.inlineFlex}>
									<Svg
										viewBox={`0 0 ${5 + this.state.streakAmount.toString().length * 10} 20`}
										height="20"
										width={5 + this.state.streakAmount.toString().length * 10}>
										<Defs>
											<LG id="rainbow" x1="0" x2="0" y1="0" y2="100%" gradientUnits="userSpaceOnUse">
												<Stop stopColor={colors.orange} offset="0%" />
												<Stop stopColor={colors.red} offset="100%" />
											</LG>
										</Defs>
										<TextSVG fill="url(#rainbow)">
											<TSpan fontSize="14" fontWeight="bold" x="0" dy="15">
												{this.state.streakAmount}
											</TSpan>
										</TextSVG>
									</Svg>
									<Text>dages streak!</Text>
								</View>

								<View style={{ width: '100%', backgroundColor: appColors.red }}>
									<TouchableOpacity
										onPress={async () => {
											this.setState({ showStreak: false });
											await this.pointAnimator(this.state.streakPoints);
											this.props.setPoints(this.props.points + this.state.streakPoints);
										}}
										style={{ width: '100%' }}>
										<LinearGradient
											start={[0, 0]}
											colors={[colors.orange, colors.red]}
											style={{
												backgroundColor: 'rgba(0,0,0, 0.1)',
												width: '100%',
												height: 50,
												justifyContent: 'center',
												alignItems: 'center',
											}}>
											<Text style={{ fontWeight: 'bold', fontSize: 30, color: appColors.white }}>
												+{this.state.streakPoints} {Lang.t('point')}
											</Text>
										</LinearGradient>
									</TouchableOpacity>
								</View>
								{/* <TouchableOpacity onPress={() => this.setState({showStreak: false})} style={{backgroundColor: appColors.bgGrey, width: "100%", height: 50, justifyContent: "center", alignItems: "center"}}>
                            <Text>Godkend</Text>
                        </TouchableOpacity> */}
							</View>
						</LinearGradient>
					</View>
				) : null}
			</LinearGradient>
		);
	}
}

const styles = StyleSheet.create({
	taskRow: {
		height: 30,
		flexDirection: 'row',
		justifyContent: 'space-between',
		// borderWidth: 2,
		borderColor: 'red',
		alignItems: 'center',
		padding: 5,
	},

	animatedCredits: {
		position: 'absolute',
		zIndex: 999,
		backgroundColor: appColors.darkOrange,
		borderColor: 'yellow',
		borderWidth: 2,
		borderRadius: 7.5,
		height: 7,
		width: 7,
	},
	content: {
		flex: 1,
	},
	contentTop: {
		backgroundColor: 'rgba(0, 0, 0, 0.01)',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		width: '100%',
		flexGrow: 1,
	},
	iconContainer: {
		width: '100%',
		padding: 30,

		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-evenly',
	},
	button: {
		height: 100,
		width: '50%',
		marginBottom: 30,

		justifyContent: 'center',
		alignItems: 'center',
	},
	icon: {
		height: 32,
		width: 32,
		backgroundColor: 'green',
	},
	textContainer: {
		width: '80%',
	},
	text: {
		marginTop: 5,
		textAlign: 'center',
		fontSize: 19,
		fontWeight: '500',
		color: '#979797',
	},
	topBar: {
		height: 55,
		width: '100%',
	},
	creditsContainer: {},
	credits: {
		height: 15,
		width: 15,
		marginLeft: 5,
	},
	creditsText: {
		color: appColors.white,
		fontWeight: 'bold',
	},
	nameText: {
		fontSize: 18,
		color: appColors.white,
		fontWeight: 'bold',
		paddingLeft: 10,
	},
	avatarContainer: {},
	avatar: {
		height: 50,
		width: 50,
		borderRadius: 25,
		borderWidth: 0.3,
		overflow: 'hidden',
		borderColor: '#FFF',
		backgroundColor: '#000',
	},
	topContainer: {
		width: '100%',
		height: 65,
		marginTop: 45,

		justifyContent: 'space-between',

		paddingLeft: 15,
		paddingRight: 15,
		zIndex: 1,
		position: 'absolute',
	},
	bgColor: {
		backgroundColor: appColors.orange,
		height: '100%',
		width: '100%',
		zIndex: 0,
	},
	bg: {
		height: '100%',
		width: '100%',
	},
	container: {
		backgroundColor: '#FFF',
		width: width,
		height: height,
		position: 'absolute',
		top: 120,
		zIndex: 2,
		borderTopLeftRadius: 30,
		borderTopRightRadius: 30,
	},
});
