import React, { useState } from 'react';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import {
	StyleSheet,
	Text,
	Platform,
	View,
	TouchableOpacity,
	ScrollView,
	Image,
	Animated,
	ActivityIndicator,
	Alert,
	Pressable,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { FontAwesome as Icon, Ionicons } from '@expo/vector-icons';
import MapView, { PROVIDER_GOOGLE } from 'react-native-maps';
import { BlurView } from 'expo-blur';
import * as Crypto from 'expo-crypto';
import * as Random from 'expo-random';

import EditObsPage from './EditObsPage';

import { baseProps, questionTemplate, questionJSONTemplate } from './classes/questionTemplate';
import Question from './classes/Question';

import GradientButton from './UI/GradientButton';
import helpers, { DAWA, locationToAddress, query, leafCount, api, leafLoop, IS_WEB } from '../helpers';
import { ALERT_TITLE } from '../MultiAppSettings';
import GradientHeader from './UI/GradientHeader';
import { useColors, appColors } from '../multiApp';

import Sheet from './classes/Sheet';
import Lang from './locale';
import WindowDimensions from './classes/Dimensions';

// const colors = helpers.appColors;
const { width, height } = WindowDimensions;

const TOP_HEIGHT: number = 151;

interface props extends baseProps {
	sheets: questionTemplate[];
	title: string;
}

interface state {}

class ObsPage extends React.Component<props> {
	state = {
		creatingObs: false,
		focusedSheet: 0,
		loading: true,
		data: [],
		progress: new Animated.Value(width * -0.99),
	};
	animatePoints = false;
	pointAnimator = false;

	setProgress(val: number) {
		Animated.timing(this.state.progress, {
			toValue: width * -1 + width * val,
			duration: 100,
			useNativeDriver: false,
		}).start();
	}

	UNSAFE_componentWillMount() {
		this.loadLocalData();
	}

	loadLocalData() {
		query('SELECT * FROM sheetData WHERE page = ? ORDER BY stamp DESC', this.props.db, [this.props.title]).then(
			async data => {
				if (data) {
					const arr = await Promise.all(
						data.map(async d => {
							const temp = new Sheet('');
							temp.parseJSON(JSON.parse(d.content));
							return temp;
						})
					).then(d => d);

					this.setState({
						data: arr,
						loading: false,
					});
				}
			}
		);
	}

	closeModal() {
		this.loadLocalData();
		Animated.timing(this.state.progress, {
			toValue: width * -0.99,
			duration: 0,
			useNativeDriver: false,
		}).start(() => this.props.showModal(null, null, false));
	}

	async createNew() {
		const target = this.props.sheets[this.state.focusedSheet];
		if (target) {
			this.setState({
				creatingObs: true,
			});
			const location = await this.props.getLocation();
			if (location) {
				const [addr, rndBytes] = await Promise.all([
					locationToAddress(location.coords.latitude, location.coords.longitude),
					Random.getRandomBytesAsync(128),
				]).then(d => d);
				// const addr = await DAWA(`adgangsadresser/reverse?x=${location.coords.longitude}&y=${location.coords.latitude}&struktur=mini`);
				if (addr) {
					const group = (this.props.groups || []).find(group => group.name === this.props.title);
					const clone = new Sheet(target.name);
					clone.parseSheetData(JSON.parse(target.toSheetJSON()));
					clone.stamp = Date.now();
					clone.sheetID = target.sheetID;
					clone.groupID = group ? Number(group.id) : 0;
					clone.location = {
						long: location.coords.longitude,
						lat: location.coords.latitude,
						name: addr.postnrnavn,
					};

					// let rndBytes = await Random.getRandomBytesAsync(128);
					const digest = await Crypto.digestStringAsync(
						Crypto.CryptoDigestAlgorithm.SHA256,
						rndBytes.join('') + Date.now()
					);
					clone.id = digest;

					await query('INSERT INTO sheetData (id, content, stamp, page) VALUES (?, ?, ?, ?)', this.props.db, [
						clone.id,
						clone.toJSON(),
						Date.now().toString(),
						this.props.title,
					]);

					this.setState({
						data: [clone, ...this.state.data],
						creatingObs: false,
					});
					requestAnimationFrame(() => this.open(0));
				} else {
					this.setState({
						creatingObs: false,
					});
				}
			} else {
				this.setState({
					creatingObs: false,
				});
			}
		}
	}

	validateAnswers(question: Question): boolean {
		if (question.functionalTypes.includes(question.type)) {
			if (question.type === 'valueChecker' || question.type === 'points') {
				const childResponse =
					question.response && question.response.length > 0 && question.response[0]
						? question.children[0].options[Number(question.response[0][0])]
						: false;
				const isCorrect = childResponse === question.correct;
				let questionCount = 0;
				leafLoop(question, () => {
					questionCount++;
				});
				if (!isCorrect) {
					return question.response.length >= questionCount; //question.response.length >= question.children.length; //!question.children.some(child => !this.validateAnswers(child));
				} else {
					return true;
				}
			} else if (question.type === 'notNeeded') {
				return true;
			} else {
				// Rest of functional components
				return !question.children.some(child => !this.validateAnswers(child));
			}
		}
		return question?.response?.length > 0 || question?.children?.[0]?.response.length > 0;
	}

	async submitObs(i) {
		const target: Sheet = this.state.data[i];
		const isValid = target.questions.filter(question => !this.validateAnswers(question));

		if (isValid.length === 0) {
			this.props.showContent(
				<View
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						zIndex: 99999,
						backgroundColor: 'rgba(0, 0, 0, 0.5)',
						height: '100%',
						width: '100%',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<ActivityIndicator size="large" />
				</View>
			);

			setTimeout(async () => {
				const location = await this.props.getLocation();
				target.stampEnd = Date.now();
				target.locationEnd = {
					long: location.coords.longitude,
					lat: location.coords.latitude,
					name: '',
				};

				let apiSheet = JSON.parse(target.toJSON());
				let data = await api('saveSheet', { data: apiSheet }, this.props.userData.token);
				if (data && !data.error) {
					await query('DELETE FROM `sheetData` WHERE id = ?', this.props.db, [target.id]);
					this.closeModal();
					if (data.data && data.data.reward && data.data.reward > 0) {
						setTimeout(async () => {
							await this.animatePoints(data.data.reward);
							this.props.setPoints(this.props.points + data.data.reward);
						}, 250);
					}
				} else {
					Alert.alert(ALERT_TITLE, Lang.t('SERVER_ERROR'));
				}
				setTimeout(() => this.props.showContent(null, false), 300);
			}, 200);
		} else {
			Alert.alert(ALERT_TITLE, Lang.t('OBS_NOT_VALID'));
		}
	}

	open(i) {
		const styles = getStyles();
		const appColors = {
			...helpers.appColors,
			...this.props.userData.colors,
		};
		const target = this.state.data[i];

		this.props.showModal(
			<React.Fragment>
				<EditObsPage
					showContent={this.props.showContent}
					showModal={this.props.showModal}
					getLocation={this.props.getLocation}
					navigation={this.props.navigation}
					db={this.props.db}
					sheet={target}
					setProgress={(val: number) => this.setProgress(val)}
					showCam={this.props.showCam}
					submitObs={() => this.submitObs(i)}
				/>
			</React.Fragment>,
			<React.Fragment>
				<GradientHeader
					userData={this.props.userData}
					title={target.location?.name ? Lang.t('Nær') + ' ' + target.location.name : ' '}
					subTitle={Lang.t('Oprettet') + ` ` + new Date(target.stamp).toLocaleDateString()}>
					<TouchableOpacity
						style={{ position: 'absolute', top: TOP_HEIGHT / 2 - 20, right: 5, zIndex: 999, padding: 20 }}
						onPress={() => this.closeModal()}>
						<Icon name="times" size={30} color={appColors.white} />
					</TouchableOpacity>
				</GradientHeader>
				<View
					style={{
						position: 'relative',
						top: 0,
						left: 0,
						width: '100%',
						height: 40,
						backgroundColor: appColors.bottomMenuBackground,
						zIndex: 99999999,
					}}>
					<View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
						<View style={{ flexDirection: 'row', opacity: 0 }}>
							<TouchableOpacity style={styles.headerSubBtn}>
								<Text style={[styles.headerSubBtnText, styles.headerSubBtnTextDisabled]}>Forrige</Text>
							</TouchableOpacity>
							<TouchableOpacity style={styles.headerSubBtn}>
								<Text style={[styles.headerSubBtnText, styles.headerSubBtnTextDisabled]}>Næste</Text>
							</TouchableOpacity>
						</View>
						<TouchableOpacity style={[styles.headerSubBtn]} onPress={() => this.submitObs(i)}>
							<Text style={[styles.headerSubBtnText]}>{Lang.t('Indsend')}</Text>
						</TouchableOpacity>
					</View>
					<Animated.View style={{ position: 'absolute', bottom: 0, transform: [{ translateX: this.state.progress }] }}>
						<LinearGradient start={[0, 0]} colors={[appColors.orange, appColors.red]} style={{ width, height: 5 }} />
					</Animated.View>
				</View>
			</React.Fragment>
		);
	}

	render() {
		const styles = getStyles();

		return (
			<View style={[styles.bg]}>
				<GradientHeader
					userData={this.props.userData}
					headerTitle={this.props.title}
					points={this.props.points || 0}
					pointAnimator={func => (this.animatePoints = func)}
					pointCallback={amount => this.props.setPoints && this.props.setPoints(amount + this.props.points)}
				/>

				<ScrollView contentInset={{ bottom: 20 }}>
					<View style={styles.messages}>
						<Text style={styles.greyTitle}>{Lang.t('Opret ny')}</Text>
						<View style={[styles.normBox]}>
							<View style={[styles.normBoxContainer, { display: this.props.sheets.length > 1 ? undefined : 'none' }]}>
								{/* <Text style={styles.normBoxText}>{Lang.t('type')}</Text> */}
								<TouchableOpacity
									activeOpacity={0.4}
									style={styles.normBoxSelectContainer}
									onPress={() => {
										this.props.showActionSheetWithOptions(
											{
												options: ['Cancel', ...this.props.sheets.map((sheet: Sheet) => sheet.name)],
												// destructiveButtonIndex: 2,
												cancelButtonIndex: 0,
											},
											focusedSheet => {
												if (focusedSheet > 0) {
													focusedSheet--;
													this.setState({
														focusedSheet,
													});
												}
											}
										);
									}}>
									<LinearGradient colors={[appColors.orange, appColors.red]} style={styles.normBoxSelectBorder}>
										<View style={styles.normBoxSelect}>
											<Text>
												{this.props.sheets[this.state.focusedSheet]
													? this.props.sheets[this.state.focusedSheet].name
													: ''}
											</Text>
											<Icon name="chevron-up" size={12} color={appColors.orange} />
										</View>
									</LinearGradient>
								</TouchableOpacity>
							</View>
							<GradientButton
								title={this.state.creatingObs ? <ActivityIndicator /> : Lang.t('Opret')}
								colorSet="blue"
								onPress={this.state.creatingObs ? () => {} : () => this.createNew()}
							/>
						</View>
						{this.state.data.length > 0 ? (
							<>
								<Text style={styles.greyTitle}>{Lang.t('Ikke gennemført')}</Text>
								{this.state.data.map((d, i) => {
									let images = [];
									leafCount(d, question => {
										if (question.type === 'upload') {
											if (question.response && Array.isArray(question.response)) {
												images = [...images, ...question.response];
											}
										}
										return 0;
									});
									return (
										<Pressable style={[styles.mapBox]} onPress={() => this.open(i)} key={i}>
											<Pressable
												onPress={() =>
													Alert.alert(ALERT_TITLE, Lang.t('DELETE_OBS'), [
														{
															text: Lang.t('CANCEL'),
															onPress: () => {},
															style: 'cancel',
														},
														{
															text: Lang.t('DELETE'),
															onPress: () => {
																query('DELETE FROM `sheetData` WHERE id = ?', this.props.db, [d.id]);
																this.setState({
																	data: this.state.data.filter(curr => curr.id !== d.id),
																});
															},
															style: 'destructive',
														},
													])
												}
												style={{ position: 'absolute', right: 10, top: 10, zIndex: 999 }}>
												<Icon name="trash" size={35} color="#f50000" />
											</Pressable>
											<MapView
												style={styles.mapBoxBg}
												provider={PROVIDER_GOOGLE}
												initialRegion={{
													latitude: d.location.lat,
													longitude: d.location.long,
													latitudeDelta: 0.0922,
													longitudeDelta: 0.0421,
												}}
												customMapStyle={helpers.mapStyle}
											/>
											<View style={styles.mapBoxInfo}>
												{d.location.name && (
													<Text style={styles.mapBoxTitle}>
														{Lang.t('Nær')} {d.location.name}
													</Text>
												)}

												{images.length > 0 && (
													<View style={styles.imagePreview}>
														{[...Array(Math.min(2, images.length))].map((_, i) => (
															<Image
																source={{ uri: `https://api.safeallday.dk/image/${images[i]}` }}
																style={styles.previewImg}
																key={i}
															/>
														))}

														{images.length > 2 && <Text style={styles.imagePreviewText}>+{images.length - 2}</Text>}
													</View>
												)}

												<View style={styles.mapBoxBottomBar}>
													<BlurView tint="dark" intensity={75} style={styles.mapBoxBottomBarInner}>
														<Text style={styles.mapBoxBottomBarText}>
															Oprettet d. {new Date(d.stamp).toLocaleDateString()}
														</Text>
													</BlurView>
												</View>
											</View>
										</Pressable>
									);
								})}
							</>
						) : (
							!this.state.loading && (
								<View style={{ justifyContent: 'center', alignItems: 'center', height: 200 }}>
									<Icon name="info-circle" size={42} color={appColors.lightGrey} />
									<Text style={{ color: appColors.lightGrey, paddingTop: 10, textAlign: 'center' }}>
										{Lang.t('Ingen igangværende rapporter! Du kan åbne en ny ovenover')}
									</Text>
								</View>
							)
						)}
					</View>
				</ScrollView>
			</View>
		);
	}
}

export default connectActionSheet(ObsPage);

export const getStyles = () =>
	useColors(appColors =>
		StyleSheet.create({
			headerSubBtn: {
				padding: 10,
			},
			headerSubBtnText: {
				color: appColors.alternativeDarkBlueText,
				fontWeight: 'bold',
			},
			bottomSubBtnText: {
				color: appColors.white,
				fontWeight: 'bold',
			},
			headerSubBtnTextDisabled: { color: appColors.grey, opacity: 0.5 },
			topTitle: {
				fontSize: 29,
				color: appColors.white,
				paddingBottom: 5,
			},
			topTitleSub: {
				fontSize: 15,
				color: appColors.white,
				paddingBottom: 6,
			},

			greyTitle: {
				color: appColors.grey,
				width: '100%',
				fontSize: 22,
				fontWeight: 'bold',
				paddingBottom: 15,
				paddingTop: 15,
			},

			normBoxText: {
				color: appColors.grey,
				fontSize: 15,
				fontWeight: 'bold',
				paddingRight: 10,
			},
			normBoxSelectContainer: {
				flexGrow: 1,
			},
			normBoxSelectBorder: {
				borderRadius: 20,
				padding: 1,
			},
			normBoxSelect: {
				borderRadius: 20,
				backgroundColor: appColors.white,
				padding: 10,
				flexDirection: 'row',
				justifyContent: 'space-between',
			},
			normBoxContainer: {
				flexDirection: 'row',
				alignItems: 'center',
				paddingBottom: 10,
				paddingTop: 10,
			},
			normBox: {
				width: '100%',
				backgroundColor: appColors.white,
				borderRadius: 15,
				padding: 15,
				shadowColor: '#000',

				shadowOffset: {
					width: 0,
					height: 5,
				},
				shadowOpacity: 0.1,
				shadowRadius: 5,
			},

			imagePreviewText: {
				color: '#FFF',
				fontWeight: 'bold',
				fontSize: 20,
				marginRight: 10,
			},

			imagePreview: {
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'flex-end',

				width: 125,

				position: 'absolute',
				right: 35,
				top: 10,
			},
			previewImg: {
				height: 44,
				width: 44,
				marginRight: 10,
			},
			mapBoxTitle: {
				fontWeight: '500',
				fontSize: 22,
				color: '#FFF',
				top: 10,
				left: 10,

				shadowColor: '#000',
				shadowOffset: {
					width: 0,
					height: 1,
				},
				shadowOpacity: IS_WEB ? 0 : 1,
				paddingLeft: IS_WEB ? 10 : 0,
				paddingTop: IS_WEB ? 10 : 0,
				shadowRadius: 15,
			},

			mapBoxBottomBarText: {
				color: '#FFF',

				shadowColor: '#000',
				shadowOffset: {
					width: 0,
					height: 1,
				},
				shadowOpacity: IS_WEB ? 0 : 1,
				shadowRadius: 5,
			},
			mapBoxBottomBar: {
				width: '100%',
				height: 35,
				position: 'absolute',
				bottom: 0,
				borderRadius: 15,

				justifyContent: 'center',
				overflow: 'hidden',
			},
			mapBoxBottomBarInner: {
				width: '100%',
				height: 35,
				borderRadius: 15,
				padding: 10,
			},
			mapBoxInfo: {
				width: '100%',
				height: '100%',
				backgroundColor: IS_WEB ? 'rgba(0,0,0,0.5)' : 'transparent',
			},
			mapBox: {
				width: '100%',
				height: 205,
				borderRadius: 15,
				overflow: IS_WEB ? 'hidden' : 'visible',

				marginBottom: 10,
				shadowColor: '#000',
				shadowOffset: {
					width: 0,
					height: 5,
				},
				shadowOpacity: 0.4,
				shadowRadius: 5,
			},
			mapBoxBg: {
				width: '100%',
				height: '100%',
				position: 'absolute',

				borderRadius: 15,
			},

			addBtnText: {
				fontSize: 20,
				color: appColors.white,
			},
			addBtn: {
				height: 70,
				backgroundColor: appColors.textGrey,
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: 50,
				margin: 10,
			},

			articleClose: {
				position: 'absolute',
				right: 10,
				top: -40,
				width: 45,
				height: 45,
				zIndex: 999,

				justifyContent: 'center',
				alignItems: 'center',
			},
			headerText: {
				color: appColors.white,
				fontSize: 28,
				fontWeight: 'bold',
				padding: 15,
				paddingTop: 37,
				paddingBottom: 21,
			},
			messages: {
				alignItems: 'center',
				paddingLeft: 15,
				paddingRight: 15,
			},

			animBg: {
				width: '100%',
				height: '100%',
				backgroundColor: appColors.bgGrey,
				position: 'absolute',
				zIndex: 9,
			},
			bg: {
				width: '100%',
				height: '100%',
				backgroundColor: appColors.bgGrey,
			},
			topGradient: {
				width: '100%',
				height: 151,
				justifyContent: 'flex-end',
			},
		})
	);
