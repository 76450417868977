import React from 'react';
import {
	StyleSheet,
	Text,
	View,
	TouchableOpacity,
	Platform,
	ScrollView,
	Image,
	KeyboardAvoidingView,
} from 'react-native';

import GradientHeader from './UI/GradientHeader';
import { IP, IS_WEB } from '../helpers';
import WindowDimensions from './classes/Dimensions';
import { useColors, appColors } from '../multiApp';
import { IS_NOVOTI_APP } from '../MultiAppSettings';

import Lang from './locale';

const IS_ANDROID = Platform.OS === 'android';
const { height } = WindowDimensions;

export interface Props {
	setPB: (file: string) => void;
	openSettings: () => void;
	userData: {
		name: string;
		pb: string;
	};
	points: number;
	navigation: any;
}

export default function PreSettingsPage(props: Props) {
	const styles = getStyles();

	return (
		<KeyboardAvoidingView behavior="padding">
			<GradientHeader headerTitle={Lang.t('Indstillinger')} points={props.points} userData={props.userData} />
			<ScrollView style={styles.newsContainer} contentInset={{ bottom: 180 }}>
				<View style={styles.centerView}>
					<Text style={[styles.text, { fontSize: 20 }]}>Hello {props.userData.name}</Text>
					<Image
						style={styles.avatar}
						resizeMode="cover"
						source={props.userData.pb ? { uri: `${IP}/image/${props.userData.pb}` } : require('../assets/avatar.png')}
					/>
					<Text style={styles.text}>Workdays you made your budget: 100%</Text>
					<Text style={styles.text}>Index: 110</Text>
					<TouchableOpacity onPress={() => props.openSettings()}>
						<View style={[styles.btn, styles.nextBtn]}>
							<Text style={{ color: appColors.white }}>{Lang.t('Update profile')}</Text>
						</View>
					</TouchableOpacity>

					{IS_NOVOTI_APP && (
						<View style={{ width: '100%', alignItems: 'center', top: 45 }}>
							<Image style={{ width: 80, height: 80 }} source={require('./UI/assets/novotiLogo.png')} />
						</View>
					)}
				</View>
			</ScrollView>
		</KeyboardAvoidingView>
	);
}

const getStyles = () =>
	useColors(appColors =>
		StyleSheet.create({
			nextBtn: {
				backgroundColor: appColors.borderColor,
			},
			btn: {
				width: '100%',
				height: 38,
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: 15,
				padding: 10,
			},
			centerView: {
				alignItems: 'center',
			},
			text: {
				marginBottom: 10,
				fontSize: 15,
				color: appColors.white,
			},
			avatar: {
				height: 150,
				width: 150,
				borderRadius: 75,
				borderWidth: 0.3,
				borderColor: '#FFF',
				backgroundColor: '#000',
				marginBottom: 20,
			},
			inputContainer: {
				backgroundColor: appColors.white,
				borderColor: appColors.bgGrey,
				borderTopWidth: 1,
				borderBottomWidth: 1,
				marginTop: 14,
				padding: 15,
				paddingTop: 10,
				paddingBottom: 10,
			},
			inputText: {
				fontSize: 14,
				paddingBottom: 4,
				color: appColors.textGrey,
			},
			input: {},
			newsContainer: {
				width: '100%',
				height: IS_WEB || IS_ANDROID ? height - 201 : '100%',
				paddingTop: 15,
				backgroundColor: appColors.bgGrey,
			},
		})
	);
