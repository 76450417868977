import React from 'react';

import { sheetGroup, baseProps } from './components/classes/questionTemplate';
import ObsPage from './components/ObsPage';

export default function SheetGroups(groups: sheetGroup[] = [], Tab: any, normProps: baseProps) {
	return groups
		.filter(group => group.sheets.length > 0)
		.map((group, key) => {
			return (
				<Tab.Screen
					name={group.name}
					key={key}
					options={({ navigation }) => ({
						title: group.name,
					})}>
					{props => <ObsPage title={group.name} {...normProps} sheets={group.sheets} groups={groups} {...props} />}
				</Tab.Screen>
			);
		});
}
