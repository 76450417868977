import { Text, View } from 'react-native';
import { IS_NOVOTI_APP } from '../MultiAppSettings';

const novoti = [
	{
		value: 'A girl should be two things: classy and fabulous.',
		author: 'Coco Chanel',
	},
	{
		value: 'The best color in the whole world is the one that looks good on you.',
		author: 'Coco Chanel',
	},
	{
		value: 'A woman who doesn’t wear perfume has no future.',
		author: 'Coco Chanel',
	},
	{
		value: 'I only drink Champagne on two occasions, when I am in love and when I am not.',
		author: 'Coco Chanel',
	},
	{
		value: 'If you’re sad, add more lipstick and attack.',
		author: 'Coco Chanel',
	},
	{
		value: 'The essence of style is a simple way of saying something complex.',
		author: 'Giorgio Armani',
	},
	{
		value: 'I believe that style is the only real luxury, that is really desirable.',
		author: 'Giorgio Armani',
	},
	{
		value: 'I like to think that I can introduce people to the idea of simplicity and elegance.',
		author: 'Giorgio Armani',
	},
	{
		value: 'It would be very hard for me to do things somebody else’s way.',
		author: 'Giorgio Armani',
	},
	{
		value: 'Good clothing is a passport to happiness.',
		author: 'Yves Saint Laurent',
	},
	{
		value: 'It takes six years to build a plane. It takes ten years to make a bar of soap.',
		author: "Eugéne Schueller, Founder L'Oreal",
	},
	{
		value: 'Succes and Lack thereof are only ever temporayy. The future lies before us, Dependent only on our efforts.',
		author: "Eugéne Schueller, Founder L'Oreal",
	},
	{
		value: "Let's do what we love and do a lot of it.",
		author: 'Marc Jacobs',
	},
	{
		value: 'Style is something each of us already has, all we need to do is find it.',
		author: 'Diane von Furstenberg',
	},
	{
		value: "I don't design clothes. I design dreams.",
		author: 'Ralph Lauren',
	},
	{
		value: 'Fashions fade, style is eternal.',
		author: 'Yves Saint Laurent',
	},
	{
		value: 'Trendy is the last stage before tacky.',
		author: 'Karl Lagerfeld',
	},
	{
		value: 'Elegance is not standing out, but being remembered.',
		author: 'Giorgio Armani',
	},
	{
		value:
			"Being well dressed hasn't much to do with having good clothes. It’s a question of good balance and good common sense.",
		author: 'Oscar de la Renta',
	},
	{
		value: 'When in doubt, wear red.',
		author: 'Bill Blass',
	},
	{
		value: 'Whoever said orange was the new pink was seriously disturbed.',
		author: 'Elle Woods',
	},
	{
		value: 'Give a girl the right kind of shoes and she can rule the world.',
		author: 'Marilyn Monroe',
	},
];

export function getQuotes() {
	if (IS_NOVOTI_APP) {
		return novoti;
	}

	return [];
}

export function RandomQuotes() {
	const data = getQuotes();

	const day = new Date().getDate();
	const target = data.length > 0 ? data[day % data.length] : undefined;
	return (
		<>
			{target && (
				<View style={{ flexDirection: 'column', alignItems: 'center', marginTop: 20 }}>
					<Text style={{ textAlign: 'center' }}>{target.value}</Text>
					<Text style={{ fontStyle: 'italic', marginTop: 8 }}>- {target.author}</Text>
				</View>
			)}
		</>
	);
}
