import React, { useState, useEffect } from 'react';
import { View, Dimensions, TouchableOpacity, Text, Animated, Easing, Alert, Image } from 'react-native';
import { getStyles } from '../ObsPage';
import { LinearGradient } from 'expo-linear-gradient';

import Question, { findQuestionWhere } from '../classes/Question';

import { appColors } from '../../multiApp';
import Lang from '../locale';
import { ALERT_TITLE, IS_NOVOTI_APP } from '../../MultiAppSettings';

const HEADER_HEIGHT = 321;
const screenHeight = Dimensions.get('window').height;

const progressX = new Animated.Value(0);

type questionTypes = Parameters<Question['get']>;

const SinglePageQuestion: React.FunctionComponent<{
	items: Question[];
	getArgs: questionTypes;
	ctx: { [key: string]: any };
}> = props => {
	const styles = getStyles();
	const [currPage, setPageState] = useState(0);
	const screenWidth = Dimensions.get('window').width;
	const pageElem = props.items[currPage];
	const ctx = props.ctx || {};

	useEffect(() => {
		progressX.setValue(-(screenWidth - ((currPage + 1) / props.items.length) * screenWidth));
	}, []);

	const setPage = (page: number) => {
		if (page >= 0 && page < props.items.length) {
			setPageState(page);

			const newValue = -(screenWidth - ((page + 1) / props.items.length) * screenWidth);
			Animated.timing(progressX, {
				toValue: newValue,
				easing: Easing.elastic(0.1),
				duration: 150,
				useNativeDriver: true,
			}).start();
		}
	};
	const toOvermenu = ctx.resetOverMenu;
	const prevEnabled = currPage > 0 || toOvermenu;
	const nextEnabled = currPage < props.items.length - 1 || toOvermenu;
	return (
		<View style={{ width: '100%' }}>
			<View style={pageStyles.questionContainer}>
				{pageElem?.get(
					props.getArgs.openModal,
					props.getArgs.showContent,
					props.getArgs.openCam,
					props.getArgs.getLocation,
					props.getArgs.triggerEvent,
					props.getArgs.sheet,
					props.getArgs.saveObs
				)}
				{IS_NOVOTI_APP && (
					<View style={{ width: '100%', alignItems: 'center', position: 'absolute', bottom: 30 }}>
						<Image style={{ width: 100, height: 100 }} source={require('./assets/novotiLogo.png')} />
					</View>
				)}
			</View>
			<View style={{ width: '100%', height: 100, backgroundColor: appColors.bottomMenuBackground, zIndex: 99999999 }}>
				<Animated.View style={{ position: 'relative', transform: [{ translateX: progressX }] }}>
					<LinearGradient
						start={[0, 0]}
						colors={[appColors.bgOrange, appColors.alternativeRed]}
						style={{ width: screenWidth, height: 5 }}
					/>
				</Animated.View>
				<View style={{ width: '100%', height: 200, flexDirection: 'row', justifyContent: 'space-between' }}>
					<View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
						<TouchableOpacity
							style={styles.headerSubBtn}
							disabled={!prevEnabled}
							onPress={() => {
								if (currPage === 0) {
									toOvermenu?.();
								} else {
									setPage(currPage - 1);
								}
							}}>
							<Text style={[styles.bottomSubBtnText, prevEnabled ? {} : styles.headerSubBtnTextDisabled]}>
								{Lang.t('Forrige')}
							</Text>
							{/* <Text style={[styles.headerSubBtnText, styles.headerSubBtnTextDisabled]}>Forrige</Text> */}
						</TouchableOpacity>
						<TouchableOpacity
							style={[styles.headerSubBtn]}
							disabled={!nextEnabled}
							onPress={() => {
								const currentQuestionCheck = pageElem.type === 'notNeeded' || pageElem?.response.length > 0;
								const uploadCheck = findQuestionWhere(pageElem, pageElem => {
									return pageElem.type === 'upload' && pageElem?.response.some(r => !r || r.length === 0);
								});

								if (!currentQuestionCheck || uploadCheck) {
									Alert.alert(ALERT_TITLE, Lang.t('NOT_ANSERED_QUESTION'));
									return;
								}

								if (currPage < props.items.length - 1) {
									setPage(currPage + 1);
								} else {
									toOvermenu?.();
								}
							}}>
							<Text style={[styles.bottomSubBtnText, nextEnabled ? {} : styles.headerSubBtnTextDisabled]}>
								{Lang.t('Næste')}
							</Text>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		</View>
	);
};

const pageStyles = {
	questionContainer: {
		// height: 100,
		// width: 100,
		justifyContent: 'center',
		height: screenHeight - HEADER_HEIGHT,
	},
};

export default SinglePageQuestion;
