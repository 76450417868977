import * as Localization from 'expo-localization';
import Constants from 'expo-constants';
import i18n from 'i18n-js';

console.log('Constant', Constants);
export const IS_NOVOTI_APP = Constants.expoConfig?.extra?.isNovoti ?? false;

i18n.locale = IS_NOVOTI_APP ? 'en-US' : Localization.locale;

if (IS_NOVOTI_APP) {
	i18n.missingTranslation = langKey => langKey;
}

const NOVOTI_RIGHTS = ['Inbox', 'Settings'];
export const rights: string[] = IS_NOVOTI_APP
	? NOVOTI_RIGHTS
	: [
			// "News",
			'Dashboard',
			'Inbox',
			'Settings',
	  ];

export const AppName = IS_NOVOTI_APP ? 'Novoti' : 'Safeallday';
export const ALERT_TITLE = IS_NOVOTI_APP ? 'We need you' : 'Safeallday';

export const defaultSheetGroup = IS_NOVOTI_APP
	? [
			{
				id: '',
				name: 'Report',
				sheets: [],
				icon: 'address-card-o',
			},
	  ]
	: [];
