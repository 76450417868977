import React, { useRef, useState } from 'react';
import {
	StyleSheet,
	Text,
	Alert,
	View,
	TextInput,
	TouchableOpacity,
	KeyboardAvoidingView,
	Platform,
	Image,
} from 'react-native';
// import { LinearGradient } from 'expo-linear-gradient';

import AnimatedButton from './UI/AnimatedButton';
// import Logo from "./UI/Logo";

import Lang from './locale';
import helpers, { api } from '../helpers';
import WindowDimensions from './classes/Dimensions';
import { ALERT_TITLE } from '../MultiAppSettings';

const colors = helpers.appColors;
const { width, height } = WindowDimensions;

export default function InputPage(props) {
	const [codeVal, setCodeVal] = useState('');
	const btnRef = useRef(false);

	const checkCode = async () => {
		const code = codeVal;
		const data = await api('checkCode', {
			code,
		});
		if (!data.error) {
			props.navigation.push('SignUp', {
				code,
				...props.route.params,
			});
		} else {
			if (btnRef.current) {
				btnRef.current.stopLoad();
			}
			Alert.alert(ALERT_TITLE, Lang.t('Code not found'));
		}
	};
	return (
		<View style={styles.bg}>
			<KeyboardAvoidingView behavior={'position'} keyboardVerticalOffset={20}>
				<View style={helpers.appStyles.inlineCenter}>
					<View style={styles.titleContainer}>
						<Text style={styles.title}>{Lang.t('Invitation code')}</Text>
					</View>
				</View>

				<View style={helpers.appStyles.inlineCenter}>
					<View style={styles.inputContainer}>
						<View style={helpers.appStyles.inlineFlex}>
							<Text style={styles.inputText}>{Lang.t('Code')}:</Text>
							<TextInput
								style={styles.input}
								onChangeText={val => setCodeVal(val)}
								autoFocus={true}
								onSubmitEditing={() => btnRef.current && btnRef.current.startLoad()}
							/>
						</View>
					</View>
				</View>

				<View style={styles.btnContainer}>
					<AnimatedButton title={Lang.t('Continue')} onPress={checkCode} ref={btnRef} />
				</View>
				<View>
					<Text style={styles.subText}>{Lang.t('SIGNUP_TERMS')}</Text>
				</View>
			</KeyboardAvoidingView>
		</View>
	);
}

const styles = StyleSheet.create({
	inputText: {
		marginLeft: 10,
		color: '#a09fb0',
		fontSize: 11,
	},
	input: {
		flexGrow: 1,
		height: '100%',
		padding: 10,
		color: helpers.appColors.orange,
		fontSize: 15,
		fontWeight: '300',
		letterSpacing: 2,
		textTransform: 'uppercase',
	},
	inputContainer: {
		width: 250,
		height: 55,
		backgroundColor: '#FFF',
		borderRadius: 15,
		marginBottom: 24,
		justifyContent: 'center',
		padding: 10,

		shadowColor: '#000',
		shadowOffset: {
			width: 2,
			height: 4,
		},
		shadowOpacity: 0.1,
		shadowRadius: 10.0,
	},
	subText: {
		color: '#A09FB0',
		textAlign: 'center',
		paddingTop: 24,
	},
	btnContainer: {
		shadowColor: colors.orange,
		shadowOffset: {
			width: 0,
			height: 0,
		},
		shadowOpacity: 0.4,
		shadowRadius: 16.0,

		// alignItems: "center",
		// backgroundColor: "red",
	},
	btn: {
		width: '100%',
		height: 60,
		borderRadius: 15,

		justifyContent: 'center',
		alignItems: 'center',
	},

	bg: {
		backgroundColor: '#ECECF3',
		width: '100%',
		height: '100%',
		padding: 30,

		justifyContent: 'center',
	},
	titleContainer: {
		width: 172,
		paddingBottom: 30,
	},
	title: {
		fontSize: 35,
		color: '#a09fb0',
		textAlign: 'center',
	},
});
