import React, { useState } from 'react';
import {
	StyleSheet,
	Text,
	View,
	Alert,
	TouchableOpacity,
	KeyboardAvoidingView,
	Platform,
	ActivityIndicator,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

import Lang from './locale';

import helpers, { api } from '../helpers';
import WindowDimensions from './classes/Dimensions';
import { ALERT_TITLE } from '../MultiAppSettings';

const colors = helpers.appColors;
const { width, height } = WindowDimensions;

export default class VerifyPage extends React.Component {
	state: { input: number[]; loading: boolean } = {
		input: [],
		loading: true,
	};
	buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9, false, 0, 'x'];

	componentDidMount() {
		setTimeout(() => this.setState({ loading: false }), 800);
	}
	// componentDidMount(){
	//     if(this.props.route.params.token)
	//     {
	//         api("verifyUsr", {}, this.props.route.params.token).then(data => {
	//             if(!data.error)
	//             {
	//                 this.setState({
	//                     loading: false,
	//                 });
	//             }else{
	//                 Alert.alert(ALERT_TITLE, "SOMETHING_WRONG"); // Der gik noget galt, kontakt en leder
	//                 this.props.navigation.goBack();
	//             }
	//         });
	//     }
	// }

	checkCode = async () => {
		this.setState({
			loading: true,
		});
		const data = await api('verifyUsrCode', { code: this.state.input.join('') }, this.props.route.params.token);
		if (!data.error) {
			this.props.showCam(
				true,
				async data => {
					if (data) {
						await api('savePB', { data: `data:image/jpg;base64,${data.base64}` }, this.props.route.params.token);
					}
					// this.props.route.params.login(true);

					this.setState({
						loading: false,
						input: [],
					});
					this.props.route.params.setUserData(this.props.route.params.userData, this.props.route.params.groups);
				},
				'front'
			);
		} else {
			Alert.alert(ALERT_TITLE, 'WRONG_CODE');
			this.setState({
				loading: false,
				input: [],
			});
		}
	};

	render() {
		return (
			<>
				{this.state.loading && (
					<View
						style={{
							position: 'absolute',
							justifyContent: 'center',
							alignItems: 'center',
							top: 0,
							left: 0,
							height: '100%',
							width: '100%',
							backgroundColor: 'rgba(0,0,0,0.5)',
							zIndex: 9999,
						}}>
						<ActivityIndicator size="large" />
					</View>
				)}
				<View style={styles.bg}>
					<View style={helpers.appStyles.inlineCenter}>
						<View style={styles.titleContainer}>
							<Text style={styles.title}>Verify {this.props.userData.phone}</Text>
						</View>
					</View>
					<View style={styles.inputContainer}>
						{[false, false, false, false].map((d, i) =>
							typeof this.state.input[i] === 'number' ? (
								<View style={styles.input} key={i}>
									<LinearGradient colors={[colors.lightOrange, colors.orange]} style={styles.input}>
										<Text style={styles.textInput}>{this.state.input[i]}</Text>
									</LinearGradient>
								</View>
							) : (
								<View style={styles.input} key={i} />
							)
						)}
					</View>

					<View style={styles.buttons}>
						{this.buttons.map((d, i) => (
							<TouchableOpacity
								key={i}
								style={styles.button}
								onPress={() => {
									let temp = [...this.state.input];
									if (d !== false) {
										if (d === 'x') {
											temp.pop();
										} else if (temp.length < 4) {
											temp.push(d);
										}
									}
									this.setState({
										input: temp,
									});
									if (temp.length === 4) {
										requestAnimationFrame(() => this.checkCode());
									}
								}}>
								<Text style={styles.buttonText}>{d}</Text>
							</TouchableOpacity>
						))}
					</View>
					{/* <View style={styles.btnContainer}>
                  <TouchableOpacity onPress={() => props.navigation.popToTop("Login")} activeOpacity={0.65}>
                      <LinearGradient
                          colors={[colors.lightOrange, colors.orange]}
                          style={styles.btn}
                          start={[0,0]}
                          end={[1,1]}
                      >
                          <Text style={helpers.appStyles.whiteColor}>
                              Continue
                          </Text>
                      </LinearGradient>
                  </TouchableOpacity>
              </View> */}
				</View>
			</>
		);
	}
}
export function VerifyPagae(props) {
	const [input, setInput] = useState([]);
	const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9, false, 0, 'x'];

	const checkCode = () => {};
}

const styles = StyleSheet.create({
	btnContainer: {
		shadowColor: colors.orange,
		shadowOffset: {
			width: 0,
			height: 0,
		},
		shadowOpacity: 0.4,
		shadowRadius: 16.0,
	},
	btn: {
		width: '100%',
		height: 60,
		borderRadius: 15,

		justifyContent: 'center',
		alignItems: 'center',
	},

	buttons: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		paddingTop: 40,
	},
	buttonText: {
		fontSize: 25,
		color: '#a09fb0',
	},
	button: {
		height: 105,
		width: 105,

		justifyContent: 'center',
		alignItems: 'center',
	},
	inputContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	input: {
		height: 65,
		width: 65,
		borderRadius: 18,
		backgroundColor: '#FFF',

		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 4,
		},
		shadowOpacity: 0.2,
		shadowRadius: 10.0,

		justifyContent: 'center',
		alignItems: 'center',
	},
	blueInput: {},
	textInput: {
		color: '#FFF',
		fontWeight: 'bold',
		fontSize: 20,
	},
	bg: {
		backgroundColor: '#ECECF3',
		width: '100%',
		height: '100%',
		padding: 30,

		justifyContent: 'center',
	},
	titleContainer: {
		width: 172,
		paddingBottom: 30,
	},
	title: {
		fontSize: 35,
		color: '#a09fb0',
		textAlign: 'center',
	},
});
