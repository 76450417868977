import React, { useState, useRef, useEffect } from 'react';
import {
	StyleSheet,
	Text,
	View,
	TouchableOpacity,
	Platform,
	ScrollView,
	TouchableWithoutFeedback,
	TextInput,
	KeyboardAvoidingView,
	Alert,
} from 'react-native';

import * as Location from 'expo-location';

import GradientHeader from './UI/GradientHeader';
import { VERSION, api, IS_WEB } from '../helpers';
import { ALERT_TITLE, AppName } from '../MultiAppSettings';
import WindowDimensions from './classes/Dimensions';
import { useColors, appColors } from '../multiApp';

import Lang from './locale';

const IS_ANDROID = Platform.OS === 'android';
const { height } = WindowDimensions;

export interface Props {
	setPB: (file: string) => void;
	navigation: any;
	openPresettings: () => void;
}

export default function SettingsPage(props: Props) {
	const styles = useStyles();
	const [inputsState, setInputs] = useState({
		first: '',
		last: '',
		phone: '',
		mail: '',
		pass: '',
		repeatPass: '',
	});

	const inputs = {
		first: useRef(null),
		last: useRef(null),
		phone: useRef(null),
		mail: useRef(null),
		pass: useRef(null),
		repeatPass: useRef(null),
	};

	const [defaultValues, setDefaultValues] = useState({
		first: '',
		last: '',
		phone: '',
		mail: '',
		pass: '',
		repeatPass: '',
	});

	const forcePos = () => Location.getCurrentPositionAsync({});
	const save = async () => {
		// props.setUserData({
		//   ...props.userData,
		//   // verifyData: false,
		//   name: `test`,
		// });
		// return;

		const data = Object.keys(inputsState).reduce((obj, key) => {
			obj[key] = inputsState[key].length > 0 ? inputsState[key] : false;
			return obj;
		}, {});
		if ((data.pass || data.repeatPass) && data.pass !== data.repeatPass) {
			Alert.alert(ALERT_TITLE, Lang.t('The given passwords must be the same'));
			return;
		}
		const resp = await api('updateInformation', { data }, props.userData.token);
		if (resp && !resp.error) {
			Alert.alert(ALERT_TITLE, Lang.t('DATA_SAVED'));
			props.setUserData({
				...props.userData,
				verifyData: false,
				name: `${data.first || defaultValues.first} ${data.last || defaultValues.last}`,
			});
		} else {
			Alert.alert(ALERT_TITLE, Lang.t('UNKOWN_ERROR'));
		}
	};

	const getData = () => {
		forcePos();
		api('getInformation', {}, props.userData.token).then(d => {
			if (d && !d.error) {
				setDefaultValues(d.data);
			}
		});
	};
	useEffect(() => getData(), []);

	useEffect(() => {
		const unsubscribe = props.navigation.addListener('blur', () => {
			props.openPresettings();
		});
		return unsubscribe;
	}, [props.navigation]);

	return (
		<KeyboardAvoidingView behavior="padding">
			<GradientHeader headerTitle={Lang.t('Indstillinger')} points={props.points} userData={props.userData} />
			<ScrollView style={styles.newsContainer} contentInset={{ bottom: 180 }}>
				<TouchableWithoutFeedback onPress={() => inputs.first.current?.focus()}>
					<View style={styles.inputContainer}>
						<Text style={styles.inputText}>{Lang.t('First name')}</Text>
						<TextInput
							style={styles.input}
							ref={inputs.first}
							onChangeText={v => setInputs({ ...inputsState, first: v })}
							defaultValue={defaultValues.first}
						/>
					</View>
				</TouchableWithoutFeedback>
				<TouchableWithoutFeedback onPress={() => inputs.last.current?.focus()}>
					<View style={styles.inputContainer}>
						<Text style={styles.inputText}>{Lang.t('Last name')}</Text>
						<TextInput
							style={styles.input}
							ref={inputs.last}
							onChangeText={v => setInputs({ ...inputsState, last: v })}
							defaultValue={defaultValues.last}
						/>
					</View>
				</TouchableWithoutFeedback>
				<TouchableWithoutFeedback onPress={() => inputs.phone.current?.focus()}>
					<View style={styles.inputContainer}>
						<Text style={styles.inputText}>{Lang.t('Phone')}</Text>
						<TextInput
							style={styles.input}
							ref={inputs.phone}
							onChangeText={v => setInputs({ ...inputsState, phone: v })}
							defaultValue={defaultValues.phone}
						/>
					</View>
				</TouchableWithoutFeedback>
				<TouchableWithoutFeedback onPress={() => inputs.mail.current?.focus()}>
					<View style={styles.inputContainer}>
						<Text style={styles.inputText}>{Lang.t('Email')}</Text>
						<TextInput
							style={styles.input}
							ref={inputs.mail}
							onChangeText={v => setInputs({ ...inputsState, mail: v })}
							defaultValue={defaultValues.mail}
						/>
					</View>
				</TouchableWithoutFeedback>
				<TouchableWithoutFeedback onPress={() => inputs.pass.current?.focus()}>
					<View style={styles.inputContainer}>
						<Text style={styles.inputText}>{Lang.t('Password')}</Text>
						<TextInput
							style={styles.input}
							ref={inputs.pass}
							onChangeText={v => setInputs({ ...inputsState, pass: v })}
						/>
					</View>
				</TouchableWithoutFeedback>
				<TouchableWithoutFeedback onPress={() => inputs.repeatPass.current?.focus()}>
					<View style={styles.inputContainer}>
						<Text style={styles.inputText}>{Lang.t('Confirm password')}</Text>
						<TextInput
							style={styles.input}
							ref={inputs.repeatPass}
							onChangeText={v => setInputs({ ...inputsState, repeatPass: v })}
						/>
					</View>
				</TouchableWithoutFeedback>

				<TouchableOpacity
					disabled={IS_WEB}
					onPress={() => {
						props.showCam(
							true,
							async data => {
								if (data) {
									let resp = await api(
										'savePB',
										{ data: `data:image/jpg;base64,${data.base64}` },
										props.userData.token
									);
									if (!resp.error && resp.data.file && props.setPB) {
										props.setPB(resp.data.file);
									}
								}
							},
							'front'
						);
					}}>
					<View style={[styles.btn, { backgroundColor: appColors.lightGrey, height: 45, opacity: IS_WEB ? 0.4 : 1 }]}>
						<Text style={{ color: appColors.white, fontWeight: 'bold', fontSize: 16 }}>{Lang.t('CHANGE_PB')}</Text>
					</View>
				</TouchableOpacity>

				<TouchableOpacity onPress={save}>
					<View style={[styles.btn, { backgroundColor: appColors.alternativeGreen, height: 65 }]}>
						<Text style={{ color: appColors.white, fontWeight: 'bold', fontSize: 16 }}>{Lang.t('Save')}</Text>
					</View>
				</TouchableOpacity>

				<TouchableOpacity onPress={() => props.signOut(false)}>
					<View style={[styles.btn, { backgroundColor: appColors.signOutBtn }]}>
						<Text style={{ color: appColors.white }}>{Lang.t('Sign out')}</Text>
					</View>
				</TouchableOpacity>

				<TouchableOpacity
					onPress={() => {
						forcePos();
					}}>
					<View style={{ alignItems: 'center' }}>
						<Text style={{ color: appColors.grey, fontWeight: '100', paddingTop: 20 }}>{AppName} 2</Text>
						<Text style={{ color: appColors.grey, fontWeight: '100', paddingTop: 5 }}>v2.{VERSION}</Text>
					</View>
				</TouchableOpacity>
			</ScrollView>
		</KeyboardAvoidingView>
	);
}

const useStyles = () =>
	useColors(appColors =>
		StyleSheet.create({
			btn: {
				width: '100%',
				height: 38,
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: 15,
			},
			inputContainer: {
				backgroundColor: appColors.white,
				borderColor: appColors.bgGrey,
				borderTopWidth: 1,
				borderBottomWidth: 1,
				marginTop: 14,
				padding: 15,
				paddingTop: 10,
				paddingBottom: 10,
			},
			inputText: {
				fontSize: 14,
				paddingBottom: 4,
				color: appColors.textGrey,
			},
			input: {},
			newsContainer: {
				width: '100%',
				height: IS_WEB || IS_ANDROID ? height - 201 : '100%',
				paddingTop: 15,
				backgroundColor: appColors.bgGrey,
			},
		})
	);
