import React, { useRef } from 'react';
import { StyleSheet, Text, View, TextInput, Alert, KeyboardAvoidingView, Platform, Image, Button } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { useForm, Controller } from 'react-hook-form';

import AnimatedButton from './UI/AnimatedButton';
import { api, appColors as colors, appStyles } from '../helpers';
import { ALERT_TITLE } from '../MultiAppSettings';

import Lang from './locale';
import WindowDimensions from './classes/Dimensions';

// const colors = helpers.appColors;
const { width, height } = WindowDimensions;

export default function SignUpPage(props) {
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			first: '',
			last: '',
			phone: '',
			mail: '',
			pass: '',
			confirm: '',
		},
	});

	const onSubmit = async (data: { [key: string]: string }) => {
		const check = Object.values(data).find(val => val.length === 0);
		if (check) {
			Alert.alert(ALERT_TITLE, Lang.t('All information must be filled'));
		} else {
			if (!data.mail.includes('@')) {
				Alert.alert(ALERT_TITLE, Lang.t('The email is not valid'));
			} else if (data.pass !== data.confirm) {
				Alert.alert(ALERT_TITLE, Lang.t('The given passwords must be the same'));
			} else if (data.pass.length < 5) {
				Alert.alert(ALERT_TITLE, Lang.t('The password must be longer'));
			} else {
				const resp = await api('signUp', {
					...data,
					code: props.route.params.code || '',
				});
				if (!resp.error) {
					props.navigation.push('Login', {
						mail: data.mail,
						pass: data.pass,
						...props.route.params,
					});
				} else {
					Alert.alert(resp.msg ? Lang.t(resp.msg) : Lang.t('UNKOWN_ERROR'));
				}
			}
		}
	};

	const refs = {
		btn: useRef(false),
		first: useRef(false),
		last: useRef(false),
		phone: useRef(false),
		mail: useRef(false),
		pass: useRef(false),
		confirm: useRef(false),
	};

	const whatToFocus = () => {
		// const target = Object.keys(refs)
		// 	.filter(key => key !== 'btn')
		// 	.find(key => {
		// 		const curr = refs[key];
		// 		return curr.current && (!curr.current._lastNativeText || curr.current._lastNativeText.length === 0);
		// 	});
		// if (target) {
		// 	refs[target].current.focus();
		// } else {
		// 	refs.btn.current.startLoad();
		// }
	};
	setTimeout(() => whatToFocus(), 1100);

	// const check = Object.keys(refs)
	//     .filter(key => key !== 'btn')
	//     .find(key => {
	//         const curr = refs[key];
	//         return (
	//             curr.current &&
	//             curr.current?.props?.validator &&
	//             !curr.current.props.validator(curr.current._lastNativeText)
	//         );
	//     });

	// if (check) {
	//     if (check === 'mail') {
	//         Alert.alert(ALERT_TITLE, Lang.t('The email is not valid'));
	//     } else {
	//         Alert.alert(ALERT_TITLE, Lang.t('All information must be filled'));
	//     }
	//     refs[check].current.focus();
	// } else if (refs.confirm.current._lastNativeText !== refs.pass.current._lastNativeText) {
	//     Alert.alert(ALERT_TITLE, Lang.t('The given passwords must be the same'));
	// } else {
	//     console.log('signUp', {
	//         first: refs.first.current._lastNativeText || '',
	//         last: refs.last.current._lastNativeText || '',
	//         mail: refs.mail.current._lastNativeText || '',
	//         pass: refs.pass.current._lastNativeText || '',
	//         phone: refs.phone.current._lastNativeText || '',
	//         code: props.route.params.code || '',
	//     });
	//     const data = await api('signUp', {
	//         first: refs.first.current._lastNativeText || '',
	//         last: refs.last.current._lastNativeText || '',
	//         mail: refs.mail.current._lastNativeText || '',
	//         pass: refs.pass.current._lastNativeText || '',
	//         phone: refs.phone.current._lastNativeText || '',
	//         code: props.route.params.code || '',
	//     });
	//     if (!data.error) {
	//         props.navigation.push('Login', {
	//             mail: refs.mail.current._lastNativeText || '',
	//             pass: refs.pass.current._lastNativeText || '',
	//             ...props.route.params,
	//         });
	//     } else {
	//         Alert.alert(Lang.t('UNKOWN_ERROR'));
	//     }
	// }
	// refs.btn.current.stopLoad();
	return (
		<View style={styles.bg}>
			<LinearGradient
				colors={[colors.bgOrange ?? colors.orange, colors.bgLightOrange ?? colors.orange]}
				style={styles.topBg}
			/>
			<KeyboardAvoidingView behavior="padding">
				<View style={styles.container} onLayout={() => null}>
					<View style={[styles.containerTop, appStyles.inlineCenter]}>
						<Text style={styles.containerTopText}>{Lang.t('Sign Up')}</Text>
					</View>
					<View style={styles.inputContainer}>
						<Controller
							control={control}
							rules={{
								required: true,
							}}
							render={({ field: { onChange, onBlur, value } }) => (
								<TextInput
									style={styles.input}
									autoCompleteType="name"
									placeholder={Lang.t('First Name')}
									placeholderTextColor="#a09fb0"
									onSubmitEditing={whatToFocus}
									onBlur={onBlur}
									onChangeText={onChange}
									value={value}
								/>
							)}
							name="first"
						/>

						<Controller
							control={control}
							rules={{
								required: true,
							}}
							render={({ field: { onChange, onBlur, value } }) => (
								<TextInput
									style={styles.input}
									placeholder={Lang.t('Last Name')}
									placeholderTextColor="#a09fb0"
									onSubmitEditing={whatToFocus}
									onBlur={onBlur}
									onChangeText={onChange}
									value={value}
								/>
							)}
							name="last"
						/>
						<Controller
							control={control}
							rules={{
								required: true,
							}}
							render={({ field: { onChange, onBlur, value } }) => (
								<TextInput
									style={styles.input}
									keyboardType="phone-pad"
									autoCompleteType="tel"
									placeholder={Lang.t('Phone')}
									placeholderTextColor="#a09fb0"
									onSubmitEditing={whatToFocus}
									onBlur={onBlur}
									onChangeText={onChange}
									value={value}
								/>
							)}
							name="phone"
						/>
						<Controller
							control={control}
							rules={{
								required: true,
							}}
							render={({ field: { onChange, onBlur, value } }) => (
								<TextInput
									style={styles.input}
									keyboardType="email-address"
									autoCompleteType="email"
									autoCapitalize="none"
									placeholder={Lang.t('Email')}
									placeholderTextColor="#a09fb0"
									onSubmitEditing={whatToFocus}
									onBlur={onBlur}
									onChangeText={onChange}
									value={value}
								/>
							)}
							name="mail"
						/>
						<Controller
							control={control}
							rules={{
								required: true,
							}}
							render={({ field: { onChange, onBlur, value } }) => (
								<TextInput
									style={styles.input}
									autoCompleteType="password"
									secureTextEntry={true}
									validator={(val: string) => val && val.length > 0}
									ref={refs.pass}
									placeholder={Lang.t('Password')}
									placeholderTextColor="#a09fb0"
									onSubmitEditing={whatToFocus}
									onBlur={onBlur}
									onChangeText={onChange}
									value={value}
								/>
							)}
							name="pass"
						/>
						<Controller
							control={control}
							rules={{
								required: true,
							}}
							render={({ field: { onChange, onBlur, value } }) => (
								<TextInput
									style={styles.input}
									autoCompleteType="password"
									secureTextEntry={true}
									placeholder={Lang.t('Confirm Password')}
									placeholderTextColor="#a09fb0"
									onSubmitEditing={whatToFocus}
									onBlur={onBlur}
									onChangeText={onChange}
									value={value}
								/>
							)}
							name="confirm"
						/>
					</View>
					<View style={styles.btnContainer}>
						<AnimatedButton
							title={Lang.t('Sign up')}
							width={width - 28 * 2 - 30 * 2}
							onPress={handleSubmit(onSubmit)}
						/>
					</View>
				</View>
			</KeyboardAvoidingView>
			<View>
				<Text style={styles.subText}>{Lang.t('SIGNUP_TERMS')}</Text>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	subText: {
		color: '#A09FB0',
		textAlign: 'center',
		paddingTop: 24,
	},
	btnText: {
		fontSize: 18,
		color: '#FFF',
	},
	btnContainer: {
		shadowColor: colors.orange,
		shadowOffset: {
			width: 0,
			height: 0,
		},
		shadowOpacity: 0.4,
		shadowRadius: 16.0,
	},
	btn: {
		width: '100%',
		height: 60,
		marginTop: 40,
		borderRadius: 15,

		justifyContent: 'center',
		alignItems: 'center',
	},

	inputContainer: {
		marginTop: 15,
	},
	input: {
		width: '100%',
		height: 50,

		marginBottom: 20,
		paddingLeft: 15,

		borderBottomWidth: 1,
		borderBottomColor: '#ECECF3',
	},
	containerTopText: {
		fontSize: 35,
		color: '#a09fb0',
	},
	containerTop: {
		marginBottom: 8,
	},
	container: {
		backgroundColor: '#FFF',
		width: '100%',
		borderRadius: 10,
		zIndex: 1,
		padding: 28,

		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 10,
		},
		shadowOpacity: 0.04,
		shadowRadius: 6.0,
	},
	topBg: {
		height: height * 0.7,
		width,
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex: 0,
	},

	bg: {
		backgroundColor: '#ECECF3',
		width: '100%',
		height: '100%',
		padding: 30,

		justifyContent: 'center',
	},
});
