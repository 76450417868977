import { View, KeyboardAvoidingView, TextInput, StyleSheet } from 'react-native';
import { useColors } from '../../../multiApp';

export function Input(props: {
	value?: string | boolean | number;
	onChange: (value: [string]) => void;
	keyboardType?: 'number-pad' | 'default';
	multiLine?: boolean;
	rightSide?: JSX.Element;
}) {
	const defaultValue = typeof props.value === 'boolean' ? '' : props.value?.toString() || '';

	return (
		<View style={styles.rangeContainer}>
			<KeyboardAvoidingView behavior="padding" enabled={true} style={{ width: '100%' }}>
				<View style={[styles.inputContainer, { height: 80 }]}>
					<TextInput
						// ref="input"
						style={styles.input}
						keyboardType={props.keyboardType ?? 'default'}
						defaultValue={defaultValue}
						multiline={props.multiLine}
						onChangeText={val => props.onChange([val])}
						// onChangeText={val => this.setResponse([val])}
					/>
					{props.rightSide}
				</View>
			</KeyboardAvoidingView>
		</View>
	);
}

const styles = useColors(colors =>
	StyleSheet.create({
		rangeContainer: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			paddingBottom: 6,
		},
		inputContainer: {
			height: 50,
			width: '100%',
			borderColor: 'rgba(0, 0, 0, 0.15)',
			borderWidth: 1,
			borderRadius: 5,
			flexDirection: 'row',
			alignItems: 'center',
			padding: 10,
		},
		input: {
			flexGrow: 1,
			height: '100%',
			marginRight: 5,
			color: '#000',
		},
	})
);
