import React, { useState, useRef } from 'react';
import { StyleSheet, Text, View, Button, Settings, Platform, AppState } from 'react-native';
import { Modalize } from 'react-native-modalize';
// import * as SQLite from 'expo-sqlite';
import DB from './components/classes/Database';
import LogBox from './components/classes/LogBox';
// import * as SecureStore from 'expo-secure-store';
import SecureStore from './components/classes/SecureStore';
import * as Location from 'expo-location';

import { NavigationContainer } from '@react-navigation/native';
import { enableScreens } from 'react-native-screens';

import { createStackNavigator as createNativeStackNavigator } from '@react-navigation/stack';
// import { createNativeStackNavigator } from 'react-native-screens/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { FontAwesome as Icon } from '@expo/vector-icons';

import Sheet from './components/classes/Sheet';

import LoginPage from './components/LoginPage';
import CamPage from './components/CamPage';
import VerifyPage from './components/VerifyPage';
import ResetPasswordPage from './components/ResetPasswordPage';
import ResetPasswordConfirmPage from './components/ResetPasswordConfirmPage';
import InputPage from './components/InputPage';
import SignUpPage from './components/SignUpPage';
import DashboardPage from './components/DashboardPage';
import NewsPage from './components/NewsPage';
import InboxPage from './components/InboxPage';
import SettingsPage from './components/SettingsPage';
import PreSettingsPage from './components/PreSettingsPage';
// import ObsPage from './components/ObsPage';
// import EditObsPage from './components/EditObsPage';

import helpers, { menus, query, api, State } from './helpers';
import SheetGroups from './sheetGroups';
import { setColors, appColors } from './multiApp';
import { sheetGroup } from './components/classes/questionTemplate';

import * as Updates from 'expo-updates';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import baseLocales from './components/baseLocales';
import i18n from 'i18n-js';
import { rights, IS_NOVOTI_APP, defaultSheetGroup } from './MultiAppSettings';

// import { LogBox } from 'react-native';

i18n.translations = baseLocales;
i18n.fallbacks = true;

enableScreens();
const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

const version = '029b';
LogBox.ignoreLogs(['Animated: `useNativeDriver` was not specified.']);

export default class App extends React.Component {
	myRefs = {
		camModal: null,
		modal: null,
	};
	db = DB.openDatabase(`db${version}.db`);

	emptyUserData = {
		points: 0,
		token: null,
		pb: false,
		notToken: false,
		name: '',
		department: 0,
		colors: appColors,
	};
	state = {
		rootkey: 0,

		frontpage: 'Dashboard',
		outside: {
			show: false,
			content: null,
		},
		showPresettings: IS_NOVOTI_APP,

		modal: {
			show: false,
			content: null,
			header: null,
		},

		camModal: {
			show: false,
			type: 'front',
			callback: () => null,
		},

		signedIn: false,

		userData: this.emptyUserData,

		sheetGroups: defaultSheetGroup,
		localI: 0,
	};

	syncInt;
	pingInt;
	_appState = AppState.currentState;

	componentWillUnmount() {
		clearInterval(this.syncInt);
		clearInterval(this.pingInt);
	}

	componentDidMount() {
		this.sync();
		this.syncInt = setInterval(() => this.sync(), 1000 * 60 * 2);

		SecureStore.getItemAsync(`userData${version}`).then(d => {
			const userData = this.state.userData;
			if (d) {
				if (JSON.stringify(userData) !== d) {
					const data = JSON.parse(d);
					// setUserData(data);

					if (data.colors) {
						setColors(data.colors);
						setTimeout(() => {
							this.setState({
								rootkey: this.state.rootkey + 1,
							});
						}, 100);
					}
					this.setState({
						userData: data,
						frontpage: data.frontpage,
					});
					State.userInfo = data;
					// setSignedIn(true);
					this.pingServer();
					requestAnimationFrame(() => {
						this.sync();
						this.updateSheetGroups();
					});

					this.locationPermCheck();
					this.pingInt = setInterval(() => this.pingServer(), 1000 * 60 * 5);
				}
			}
		});

		this.db.transaction(tx => {
			tx.executeSql(
				'CREATE TABLE IF NOT EXISTS news (id INTEGER primary key NOT NULL, content TEXT, title TEXT, views TEXT, unix TEXT, read int);'
			);
			tx.executeSql(
				'CREATE TABLE IF NOT EXISTS sheetData (id TEXT primary key NOT NULL, stamp INTEGER, content TEXT, page STRING);'
			);
			tx.executeSql(
				'CREATE TABLE IF NOT EXISTS sheetGroup (id TEXT primary key NOT NULL, content TEXT, name TEXT, icon TEXT);'
			);
			tx.executeSql('CREATE TABLE IF NOT EXISTS quiz (stamp TEXT primary key NOT NULL, content TEXT);');
			tx.executeSql('CREATE TABLE IF NOT EXISTS settings (name TEXT primary key NOT NULL, content TEXT);');
			this.updateSheetGroups();
		});

		query("SELECT * FROM `settings` WHERE `name` = 'locale'", this.db).then(d => {
			if (d && d.length > 0) {
				const locale = JSON.parse(d[0].content);
				if (!locale === null) {
					i18n.translations = locale;
				}
			}
		});

		AppState.addEventListener('change', nextAppState => {
			if (this._appState.match(/inactive|background/) && nextAppState === 'active') {
				this.checkUpdates();
			}

			this._appState = nextAppState;
		});
		this.checkUpdates();
	}

	async checkUpdates() {
		try {
			const update = await Updates.checkForUpdateAsync();
			if (update.isAvailable) {
				// ... notify user of update ...
				await Updates.fetchUpdateAsync();
				await Updates.reloadAsync();
			}
		} catch (e) {
			// handle or log error
		}
	}

	async requestNotifications() {
		const settings = await Notifications.getPermissionsAsync();
		const valid = settings.granted || settings.ios?.status === Notifications.IosAuthorizationStatus.PROVISIONAL;
		if (valid) {
			const token = await Notifications.getDevicePushTokenAsync();
			this.saveUserData({
				...State.userInfo,
				notToken: token.data,
			});
			await api(
				'saveToken',
				{
					type: 'notification',
					value: token.data,
					id: `${Device.brand}-${Device.modelName}`,
				},
				this.state.userData.token
			);
		}
	}

	async pingServer() {
		const resp = await api('ping', {}, this.state.userData.token);
	}

	async sync() {
		Location.getCurrentPositionAsync({}).catch(error => {
			console.log('Sync location error', error);
		}); // Force Location.getLastKnownPositionAsync to get updated.
		const uData = State.userInfo || this.state.userData;
		if (uData && uData.token) {
			let groupData = (await query('SELECT * FROM sheetGroup ORDER BY id ASC', this.db)) || [];
			groupData = groupData.map(d => ({ ...d, sheets: JSON.parse(d.content), id: Number(d.id) }));

			const resp = await api(
				'sync',
				{
					sheetIDs: groupData.map(d => d.sheets.map(d => d.sheetID)).flat(),
				},
				uData.token
			);
			if (resp) {
				const data = resp.data;
				data.removeSheets;
				{
					// Remove old sheets
					groupData = groupData.map(group => {
						group.sheets = group.sheets.filter(sheet => !data.removeSheets.includes(sheet.sheetID));
						return group;
					});
				}
				if (data.groups) {
					// Remove old groups
					groupData = groupData.filter(group => data.groups.find(d => d.id === group.id));

					// Add new sheets
					groupData = groupData.map(group => {
						const target = data.groups.find(d => d.id === group.id);
						group.sheets = [
							...group.sheets,
							...(target ? target.sheets : []).filter(sheet => !group.sheets.find(d => d.id === sheet.id)),
						];
						return group;
					});

					// Find and add new groups
					const extras = data.groups.filter(group => !groupData.find(d => d.id === group.id));
					groupData = [...groupData, ...extras];
					await query(`DELETE FROM sheetGroup;`, this.db);

					await Promise.all(
						groupData.map(group =>
							query(`INSERT INTO sheetGroup (id, name, icon, content) VALUES (?, ?, ?, ?)`, this.db, [
								group.id,
								group.name,
								group.icon,
								JSON.stringify(group.sheets),
							])
						)
					);
					this.updateSheetGroups();
				}
				if (data.department) {
					const newData = {
						...uData,
						department: Number(data.department),
					};
					// State.userInfo = newData;
					// setUserData(newData);
					this.saveUserData(newData);
				}
			}
		}

		const locales = await api('getLocales', { short: true }, this.state.userData.token);
		if (locales && !locales.error) {
			i18n.translations = locales.data;
			const check = await query("SELECT `name` FROM `settings` WHERE `name` = 'locale'", this.db);
			if (check && check.length > 0) {
				await query("UPDATE `settings` SET `content` = ? WHERE `name` = 'locale'", this.db, [
					JSON.stringify(locales.data),
				]);
			} else {
				await query('INSERT INTO `settings` (`name`, `content`) VALUES (?, ?)', this.db, [
					'locale',
					JSON.stringify(locales.data),
				]);
			}

			this.setState({
				localI: this.state.localI + 1,
			});
		}
	}

	saveUserData(newUsrData) {
		if (newUsrData.token || 1) {
			if (newUsrData.colors) {
				setColors(newUsrData.colors);
			}

			State.userInfo = newUsrData;
			SecureStore.setItemAsync(`userData${version}`, JSON.stringify(newUsrData));
			this.setState({
				userData: newUsrData,
			});

			if (newUsrData.frontpage && this.state.frontpage !== newUsrData.frontpage) {
				this.setState({
					frontpage: newUsrData.frontpage,
				});
			}
		}
	}

	getLocation = () =>
		Location.getLastKnownPositionAsync().catch(_ => ({
			coords: {
				latitude: 55.501887,
				longitude: 9.794188,
			},
		}));

	async updateSheetGroups() {
		const data = await query('SELECT * FROM sheetGroup ORDER BY id ASC', this.db);
		const newGroups = [];
		for (let i = 0; i < (data || []).length; i++) {
			const curr = data[i];
			const check = newGroups.some(d => d.id === curr.id); // Shouldnt be needed!!!!
			if (!check) {
				const group: sheetGroup = {
					id: curr.id,
					name: curr.name,
					icon: curr.icon,
					sheets: (JSON.parse(curr.content) || []).map(d => {
						const tempSheet = new Sheet('TestSheet');
						tempSheet.parseJSON(d);
						return tempSheet;
					}),
				};
				newGroups.push(group);
			}
		}
		this.setState({
			sheetGroups: newGroups,
		});
	}

	showCam(show: boolean = true, callback = () => null, type = 'back') {
		this.setState({
			camModal: {
				show,
				type,
				callback,
			},
		});

		if (show) {
			this.myRefs.camModal?.open();
			//   camModalizeRef.current?.open();
		} else {
			this.myRefs.camModal?.close();
		}
	}

	showContent = (content, show: boolean = true) =>
		this.setState({
			outside: {
				show,
				content,
			},
		});

	showModal(content, header = null, show: boolean = true) {
		this.setState({
			modal: {
				show,
				content,
				header,
			},
		});
		if (show) {
			this.myRefs.modal?.open();
		} else {
			this.myRefs.modal?.close();
		}
	}

	setPoints = (points: number) => {
		const newUsrData = {
			...this.state.userData,
			points,
		};
		this.saveUserData(newUsrData);
	};

	setSignedIn(d) {
		this.setState({
			signedIn: d,
		});
		this.locationPermCheck();
	}

	async locationPermCheck() {
		let { status } = await Location.requestForegroundPermissionsAsync();
		if (status !== 'granted') {
			console.log('Show error msg');
			//setErrorMsg('Permission to access location was denied');
		}
	}

	render() {
		const userData = this.state.userData;
		const IS_WEB = Platform.OS === 'web';
		return (
			<ActionSheetProvider>
				<NavigationContainer>
					{this.state.outside.show && this.state.outside.content}

					<Modalize
						HeaderComponent={this.state.modal.header}
						panGestureEnabled={Platform.OS === 'ios'}
						ref={val => (this.myRefs.modal = val)}>
						{this.state.modal.content}
					</Modalize>

					<Modalize
						ref={val => (this.myRefs.camModal = val)}
						onClosed={() => {
							this.state.camModal.callback(false);
							// showCam(false);
						}}>
						<CamPage
							type={this.state.camModal.type}
							hide={data => {
								this.state.camModal.callback(data);
								this.showCam(false);
							}}
						/>
					</Modalize>

					{!userData.token ? (
						<Stack.Navigator
							// initialRouteName="SignUp"
							screenOptions={{
								headerShown: false,
							}}>
							<Stack.Screen
								name="Login"
								initialParams={{
									login: d => this.setSignedIn(d),
									setUserData: async (data, groups = []) => {
										try {
											// SecureStore.setItemAsync(`userData${version}`, JSON.stringify(data));
											for (let i = 0; i < groups.length; i++) {
												const group = groups[i];
												const temp = await query(
													`INSERT INTO sheetGroup (id, name, icon, content) VALUES (?, ?, ?, ?)`,
													this.db,
													[group.id, group.name, group.icon, JSON.stringify(group.sheets)]
												);
											}
											// await Promise.all(groups.map(group =>  query(`INSERT INTO sheetGroup (id, name, icon, content) VALUES (?, ?, ?, ?)`, db, [
											//   group.id,
											//   group.name,
											//   group.icon,
											//   JSON.stringify(group.sheets),
											// ])));
											this.pingServer();
										} catch (error) {
											console.log('User error', error);
										}

										this.updateSheetGroups();
										// State.userInfo = data;
										// setUserData(data);
										this.saveUserData(data);
										//   setSignedIn(true);
										this.locationPermCheck();
										requestAnimationFrame(() => this.sync());
									},
								}}>
								{props => (
									<LoginPage
										db={this.db}
										localeKey={this.state.localI}
										showModal={(a, b, c) => this.showModal(a, b, c)}
										showCam={(a, b, c) => this.showCam(a, b, c)}
										locale={i18n}
										{...props}
									/>
								)}
							</Stack.Screen>
							<Stack.Screen name="SignUp" component={SignUpPage} />
							<Stack.Screen name="Invitation" component={InputPage} />
							<Stack.Screen name="Verify">
								{props => (
									<VerifyPage
										db={this.db}
										userData={this.state.userData}
										showModal={(a, b, c) => this.showModal(a, b, c)}
										showCam={(a, b, c) => this.showCam(a, b, c)}
										locale={i18n}
										{...props}
									/>
								)}
							</Stack.Screen>
							<Stack.Screen name="resetPassword">
								{props => <ResetPasswordPage db={this.db} locale={i18n} {...props} />}
							</Stack.Screen>
							<Stack.Screen name="resetPasswordConfirm">
								{props => <ResetPasswordConfirmPage db={this.db} locale={i18n} {...props} />}
							</Stack.Screen>
						</Stack.Navigator>
					) : (
						<Tab.Navigator
							key={this.state.rootkey}
							initialRouteName="Dashboard"
							screenOptions={({ route }) => ({
								tabBarLabel: (focused, color, size) => {
									const menu =
										menus.find(d => d.slug === route.name) || this.state.sheetGroups.find(d => d.name === route.name);
									return (
										<Text
											style={{
												fontSize: 10,
												marginLeft: IS_WEB ? 20 : 0,
												color: focused.focused
													? helpers.appColors.bottomMenuActive
													: helpers.appColors.bottomMenuInactive,
											}}>
											{i18n.t(menu ? menu.name : '')}
										</Text>
									);
								},
								tabBarIcon: ({ focused, color, size }) => {
									const menu =
										menus.find(d => d.slug === route.name) || this.state.sheetGroups.find(d => d.name === route.name);
									return (
										<Icon
											name={menu?.icon}
											userData={this.state.userData}
											style={focused ? null : styles.iconStyle}
											size={menu?.size || 25}
											color={focused ? appColors.bottomMenuActive : appColors.bottomMenuInactive}
										/>
									);
								},
							})}
							tabBarOptions={{
								activeTintColor: appColors.bottomMenuActive,
								inactiveTintColor: appColors.bottomMenuInactive,

								activeBackgroundColor: 'transparent',

								inactiveBackgroundColor: 'transparent',
								style: {
									borderTopColor: appColors.bottomMenuBackground,
									backgroundColor: appColors.bottomMenuBackground,
								},
							}}>
							<Tab.Screen
								name="Dashboard"
								options={({ route }) => ({
									title: 'Oversigt',
									tabBarVisible: false,
								})}>
								{props => (
									<DashboardPage
										frontpage={this.state.frontpage}
										db={this.db}
										localeKey={this.state.localI}
										points={userData.points}
										requestNotifications={() => this.requestNotifications()}
										setPoints={p => this.setPoints(p)}
										rights={rights}
										showModal={(a, b, c) => this.showModal(a, b, c)}
										getLocation={this.getLocation}
										userData={this.state.userData}
										modalState={this.state.modal}
										groups={this.state.sheetGroups}
										locale={i18n}
										{...props}
									/>
								)}
							</Tab.Screen>
							{/* {rights.includes('News') && (
								<Tab.Screen
									name="Dashboard"
									options={({ route }) => ({
										title: 'Oversigt',
										tabBarVisible: false,
									})}>
									{props => (
										<DashboardPage
											frontpage={this.state.frontpage}
											db={this.db}
											localeKey={this.state.localI}
											points={userData.points}
											requestNotifications={() => this.requestNotifications()}
											setPoints={p => this.setPoints(p)}
											rights={rights}
											showModal={(a, b, c) => this.showModal(a, b, c)}
											getLocation={this.getLocation}
											userData={this.state.userData}
											modalState={this.state.modal}
											groups={this.state.sheetGroups}
											locale={i18n}
											{...props}
										/>
									)}
								</Tab.Screen>
							)} */}
							{rights.includes('News') && (
								<Tab.Screen
									name="News"
									options={({ navigation }) => ({
										title: 'Nyheder',
									})}>
									{props => (
										<NewsPage
											db={this.db}
											points={userData.points}
											setPoints={p => this.setPoints(p)}
											showModal={(a, b, c) => this.showModal(a, b, c)}
											getLocation={this.getLocation}
											userData={this.state.userData}
											showContent={(a, b) => this.showContent(a, b)}
											locale={i18n}
											{...props}
										/>
									)}
								</Tab.Screen>
							)}

							{SheetGroups(this.state.sheetGroups, Tab, {
								db: this.db,
								showModal: (a, b, c) => this.showModal(a, b, c),
								showContent: (a, b) => this.showContent(a, b),
								getLocation: () => this.getLocation(),
								userData,
								showCam: (a, b, c) => this.showCam(a, b, c),
								showContent: (a, b) => this.showContent(a, b),
								points: userData.points,
								setPoints: p => this.setPoints(p),
							})}

							{rights.includes('Inbox') && (
								<Tab.Screen
									name="Inbox"
									options={({ navigation }) => ({
										title: 'Indbakke',
									})}>
									{props => (
										<InboxPage
											db={this.db}
											points={userData.points}
											setPoints={p => this.setPoints(p)}
											showModal={(a, b, c) => this.showModal(a, b, c)}
											getLocation={this.getLocation}
											userData={this.state.userData}
											showContent={(a, b) => this.showContent(a, b)}
											locale={i18n}
											{...props}
										/>
									)}
								</Tab.Screen>
							)}
							{/* <Tab.Screen name="ObsOLD" options={({ navigation }) => ({
                  title: 'Observation'
                })}>
                  {(props) => <ObsPage db={this.db} points={userData.points} setPoints={p => this.setPoints(p)} showModal={(a,b,c) => this.showModal(a, b, c)} getLocation={this.getLocation} userData={this.state.userData} showCam={(a, b, c) => this.showCam(a, b, c)} showContent={(a, b) => this.showContent(a, b)} sheets={tempSheets} locale={i18n} {...props}/>}
                </Tab.Screen> */}
							{rights.includes('Settings') && (
								<Tab.Screen
									name="Settings"
									options={({ navigation }) => ({
										title: 'Indstillinger',
									})}>
									{props =>
										this.state.showPresettings ? (
											<PreSettingsPage
												db={this.db}
												points={userData.points}
												setPoints={p => this.setPoints(p)}
												showModal={(a, b, c) => this.showModal(a, b, c)}
												getLocation={this.getLocation}
												userData={this.state.userData}
												openSettings={() => this.setState({ showPresettings: false })}
												showContent={(a, b) => this.showContent(a, b)}
												locale={i18n}
												{...props}
											/>
										) : (
											<SettingsPage
												db={this.db}
												points={userData.points}
												setUserData={data => {
													// setUserData(data);
													// State.userInfo = data;
													this.saveUserData(data);
												}}
												openPresettings={() => IS_NOVOTI_APP && this.setState({ showPresettings: true })}
												setPoints={p => this.setPoints(p)}
												showModal={(a, b, c) => this.showModal(a, b, c)}
												getLocation={this.getLocation}
												userData={this.state.userData}
												showCam={(a, b, c) => this.showCam(a, b, c)}
												signOut={() => {
													// setSignedIn(false);
													this.saveUserData(this.emptyUserData);
													SecureStore.deleteItemAsync(`userData${version}`);
													this.setState({
														groupData: [],
														frontpage: 'Dashboard',
													});
													// setSheetGroups([]);

													query('DELETE FROM sheetGroup WHERE 1 = 1;', this.db);
													query('DELETE FROM news WHERE 1 = 1;', this.db);
													query('DELETE FROM sheetData WHERE 1 = 1;', this.db);
													query('DELETE FROM quiz WHERE 1 = 1;', this.db);
												}}
												setPB={(file: string) => {
													this.saveUserData({
														...this.state.userData,
														pb: file,
													});
												}}
												showContent={(a, b) => this.showContent(a, b)}
												locale={i18n}
												{...props}
											/>
										)
									}
								</Tab.Screen>
							)}
						</Tab.Navigator>
					)}
				</NavigationContainer>
			</ActionSheetProvider>
		);
	}
}

const IS_WEB = Platform.OS === 'web';
const styles = StyleSheet.create({
	iconStyle: {
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 4,
		},
		shadowOpacity: IS_WEB ? 0 : 0.1,
		shadowRadius: 4,
	},
	btnContainer: {
		height: 46,
		width: 46,
		alignItems: 'center',
		justifyContent: 'center',
	},
	menuBtn: {
		height: 46,
		width: 46,

		justifyContent: 'center',
		alignItems: 'center',
	},
	selectedBtn: {
		backgroundColor: helpers.appColors.bgGrey,
		height: 46,
		width: 46,
		borderRadius: 46 / 2,

		shadowColor: helpers.appColors.bgGrey,
		shadowOffset: {
			width: 0,
			height: 0,
		},
		shadowOpacity: 0.5,
		shadowRadius: 15,
	},
	container: {
		width: '100%',
		height: 70,
		//backgroundColor: "rgba(255, 255, 255, 0.6)",
		position: 'absolute',
		bottom: 0,
		justifyContent: 'space-evenly',
		paddingBottom: 18,
	},
});
