import { Text, Image } from 'react-native';
import { IS_NOVOTI_APP } from '../MultiAppSettings';
import SafealldayLogo from '../components/UI/Logo';

export function Logo({ width, type }: { width: number; type: 1 | 0 }) {
	if (IS_NOVOTI_APP) {
		return (
			<Image
				source={require('../components/UI/assets/novotiLogo.png')}
				style={{ width, height: width }}
				resizeMode="contain"
			/>
		);
	} else {
		return (
			<>
				<SafealldayLogo width={width} type={type} />
				{type === 0 && (
					<Text style={{ fontSize: 45, color: '#FFF', fontFamily: 'System', fontWeight: 'bold' }}>Safeallday</Text>
				)}
			</>
		);
	}
}
