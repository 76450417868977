import React, { useState } from 'react';
import {
	StyleSheet,
	Text,
	View,
	Alert,
	TouchableOpacity,
	KeyboardAvoidingView,
	Platform,
	ActivityIndicator,
} from 'react-native';
import * as Lang from 'i18n-js';

import { LinearGradient } from 'expo-linear-gradient';
import helpers, { api } from '../helpers';
import { ALERT_TITLE } from '../MultiAppSettings';

const colors = helpers.appColors;
export default function ResetPasswordConfirmPage(props: { navigation: any }) {
	const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9, '+', 0, 'x'];
	const [isLoading, setLoading] = useState(false);
	const [input, setInput] = useState<(string | number | boolean)[]>([]);

	const resetPassword = async () => {
		setLoading(true);
		const resp = await api('resetPassword', {
			phone: input.join(''),
		});
		console.log('Resp', resp);
		setLoading(false);
		if (resp && !resp.error) {
			props.navigation.push('resetPasswordConfirm');
		} else {
			Alert.alert(ALERT_TITLE, Lang.t('UNKOWN_ERROR'));
		}
	};
	return (
		<>
			{isLoading && (
				<View
					style={{
						position: 'absolute',
						justifyContent: 'center',
						alignItems: 'center',
						top: 0,
						left: 0,
						height: '100%',
						width: '100%',
						backgroundColor: 'rgba(0,0,0,0.5)',
						zIndex: 9999,
					}}>
					<ActivityIndicator size="large" />
				</View>
			)}
			<View style={styles.bg}>
				<View style={helpers.appStyles.inlineCenter}>
					<View style={styles.titleContainer}>
						<Text style={styles.title}>{Lang.t('Phone')}</Text>
					</View>
				</View>
				<View style={styles.inputContainer}>
					<View style={styles.input}>
						<Text style={styles.textInput}>{input.join(' ')}</Text>
					</View>
				</View>

				<View style={styles.buttons}>
					{buttons.map((d: string | number | boolean, i) => (
						<TouchableOpacity
							key={i}
							style={styles.button}
							onPress={() => {
								let temp = [...input];
								if (d !== false) {
									if (d === 'x') {
										temp.pop();
									} else {
										temp.push(d);
									}
								}
								setInput(temp);
								// if (temp.length === 4) {
								// 	requestAnimationFrame(() => this.checkCode());
								// }
							}}>
							<Text style={styles.buttonText}>{d}</Text>
						</TouchableOpacity>
					))}
					<TouchableOpacity onPress={resetPassword} style={{ width: '100%' }}>
						<LinearGradient colors={[colors.lightOrange, colors.orange]} style={styles.input}>
							<Text style={[styles.textInput, { color: '#FFF' }]}>{Lang.t('SEND_CODE')}</Text>
						</LinearGradient>
					</TouchableOpacity>
				</View>
			</View>
		</>
	);
}

const styles = StyleSheet.create({
	btnContainer: {
		shadowColor: colors.orange,
		shadowOffset: {
			width: 0,
			height: 0,
		},
		shadowOpacity: 0.4,
		shadowRadius: 16.0,
	},
	btn: {
		width: '100%',
		height: 60,
		borderRadius: 15,

		justifyContent: 'center',
		alignItems: 'center',
	},

	buttons: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		paddingTop: 40,
	},
	buttonText: {
		fontSize: 25,
		color: '#a09fb0',
	},
	button: {
		height: 105,
		width: 105,

		justifyContent: 'center',
		alignItems: 'center',
	},
	inputContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	input: {
		height: 65,
		width: '100%',
		borderRadius: 18,
		backgroundColor: '#FFF',

		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 4,
		},
		shadowOpacity: 0.2,
		shadowRadius: 10.0,

		justifyContent: 'center',
		alignItems: 'center',
	},
	blueInput: {},
	textInput: {
		color: '#000',
		fontWeight: 'bold',
		fontSize: 20,
	},
	bg: {
		backgroundColor: '#ECECF3',
		width: '100%',
		height: '100%',
		padding: 30,

		justifyContent: 'center',
	},
	titleContainer: {
		width: 172,
		paddingBottom: 30,
	},
	title: {
		fontSize: 35,
		color: '#a09fb0',
		textAlign: 'center',
	},
});
